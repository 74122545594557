import React, { useState, useEffect } from 'react'
import DashboardNavbar from '../CommonCompoment/DashboardNavbar/DashboardNavbar'
import { Col, Container, Row, Table } from 'react-bootstrap';
import "./MyContact.css"
// google font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import { deleteContactApi, myContacts, singleContact } from '../../api/contact';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import Loader from '../CommonCompoment/Loader/Loader';
import Pagination from '../CommonCompoment/Pagination/Pagination';
import { countCheckedItems, handleForbiddenError, multiplyBySixAndShowSeries, removeCookie, shortEmail } from '../CommonInterface/CommonFunction';
import AddContact from './AddContact';
import GroupList from './GroupList';
import EditContact from './EditContact';
import { CSVLink } from 'react-csv';
import { MyContactInterface } from '../CommonInterface/CommonInterface';
import ExportFile from './ExportFile';
import CreateGroup from './CreateGroup';
import { showNotifications } from '../../helpers/toaster';
import { useNavigate, useParams } from 'react-router-dom';
import SingleContact from './SingleContact';
import SmallLoader from '../CommonCompoment/Loader/SmallLoader';


const MyContact = () => {
    const navigate = useNavigate();
    const { id }: any = useParams();
    const [searchTerm, setSearchTerm] = useState("");
    const [searchString, setSearchString] = useState("");
    const [contacts, setContacts] = useState<MyContactInterface[]>([]);
    const [contactView, setContactView] = useState<number | null>(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState<any>([]);
    const [spinLoading, setSpinLoading] = useState<boolean>(false);
    const [fadeAnimation, setFadeAnimation] = useState(false);
    const [filterTag, setFilterTag] = useState("ALL");
    const [userIds, setUserIds] = useState<string[]>([]);
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    // pagination state
    const [totalValue, setTotalValue] = useState<any>();
    const [limitValue, setLimitValue] = useState<any>();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState<number>(6);
    const [pageValue, setPageValue] = useState<number>();
    const pageCount = Math.ceil(totalValue / limitValue);
    const [prevButton, setPrevButton] = useState<boolean>(false);
    const [nextButton, setNextButton] = useState<boolean>(false);
    const [count, setCount] = useState<number>(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [groupShow, setGroupShow] = useState(false);
    const handleGroupClose = () => setGroupShow(false);
    const handleGroupShow = () => setGroupShow(true);

    const [contactShow, setContactShow] = useState(false);
    const handleContactClose = () => setContactShow(false);
    const handleContactShow = () => setContactShow(true);
    // edit
    const [editShow, setEditShow] = useState(false);
    const handleEditClose = () => {
        setEditShow(false);
        setPhoneNumber("");
    }
    const [groupIdentifier, setGroupID] = useState("");
    const [groupName, setGroupName] = useState("");
    const [resultLength, setResultLength] = useState<number>(0);
    const [firstNameBy, setFirstNameBy] = useState<string>("firstName");
    const [lastNameBy, setLastNameBy] = useState<string>("lastName");
    const [createdBy, setCreatedBy] = useState<string>("created");
    const [firstNameOrder, setFirstNameOrder] = useState<string>("asc");
    const [lastNameOrder, setLastNameOrder] = useState<string>("asc");
    const [createdOrder, setCreatedOrder] = useState<string>("asc");
    const [groupListShow, setGroupListShow] = useState(false);
    const [contactInfo, setContactInfo] = useState({});
    const pageResult = multiplyBySixAndShowSeries(totalValue);
    // export file
    const [exportShow, setExportShow] = useState(false);
    const handleExportClose = () => setExportShow(false);
    const handleExportShow = () => setExportShow(true);


    const handleGroupListClose = () => {
        setGroupListShow(false);
        setShow(true);
    }
    //    contact searching
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setSearchString(event.target.value);
    };
    const contactResults = contacts.filter((item: any) => {
        const fullName = `${item.firstName} ${item.lastName} ${item.email} ${item.group ? item.group.groupName : ''}`;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase())
    });


    //get my contacts 
    let getMyContacts = () => {
        myContacts(limit, page, filterTag, searchString).then(data => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
                else {
                    setContacts([])
                }
            }
            else {
                setResultLength(data.body.contacts.length);
                setTotalValue(data.body.total);
                setContactView(data.body.total);
                setLimitValue(data.body.limit);
                setPageValue(data.body.page);
                const updatedResponseTable = data.body.contacts.map((r: MyContactInterface) => ({ ...r, isChecked: false, }));
                setContacts(updatedResponseTable)
            }
        })
    }

    //load my contacts on load
    useEffect(() => {
        setFadeAnimation(true);
        getMyContacts();
        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }
    }, [page, totalValue, limitValue, limit, count, filterTag, searchString]);

    useEffect(() => {
        if (id) {
            singleContact(id).then((data) => {
                setContactInfo(data.body);
            })
            setContactShow(true);
        }
    }, []);

    const nextPage = (pageNumber: number) => {
        setPage(page + pageNumber)
        setNextButton(true)
    }
    const prevPage = (pageNumber: number) => {
        setPage(page - pageNumber)
    }
    const showResult = (value: number) => {
        setPage(1)
        setLimit(value)
    }
    // delete contact
    const deleteContact = (contactId: string) => {
        setSpinLoading(true)
        deleteContactApi(contactId).then((data) => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 401) {
                    removeCookie('docoteam');
                    return navigate("/login");
                }
                else {
                    showNotifications("error", data.message);
                }
            }
            else {
                if (contactId === id) {
                    setTimeout(() => {
                        setSpinLoading(false)
                        getMyContacts();
                        return navigate("/my-contact");
                    }, 500)
                }
                else {
                    setTimeout(() => {
                        setSpinLoading(false)
                        getMyContacts();
                    }, 500)
                }

            }
        })
    }
    // table sorting
    const sortTable = (columnKey: string) => {
        let sortedData = [...contactResults];
        if (columnKey === 'firstName') {
            setFirstNameBy(columnKey);
            setFirstNameOrder(firstNameBy === 'firstName' ? (firstNameOrder === 'asc' ? 'desc' : 'asc') : 'asc');
            sortedData.sort((a, b) => {
                return (firstNameOrder === 'asc' ? a[columnKey].localeCompare(b[columnKey]) : b[columnKey].localeCompare(a[columnKey]));
            });
        } else if (columnKey === 'lastName') {
            setLastNameBy(columnKey);
            setLastNameOrder(lastNameBy === 'lastName' ? (lastNameOrder === 'asc' ? 'desc' : 'asc') : 'asc');
            sortedData.sort((a, b) => {
                return (lastNameOrder === 'asc' ? a[columnKey].localeCompare(b[columnKey]) : b[columnKey].localeCompare(a[columnKey]));
            });
        } else if (columnKey === 'created') {
            setCreatedBy(columnKey);
            setCreatedOrder(createdBy === 'created' ? (createdOrder === 'asc' ? 'desc' : 'asc') : 'asc');
            sortedData.sort((a, b) => {
                return (createdOrder === 'asc' ? a[columnKey].localeCompare(b[columnKey]) : b[columnKey].localeCompare(a[columnKey]));
            });
        }
        setContacts(sortedData);
    };
    // edit contact
    const editContact = (contactId: string) => {
        singleContact(contactId).then((data) => {
            setContactInfo(data.body);
        })
        setContactShow(false);
        setEditShow(true);
    }
    const tableData = contactResults.map((contact: any) => ({
        'First Name': contact.firstName,
        'Last Name': contact.lastName,
        'Email': contact.email,
        'Created By': contact.createdBy,
        'Group': contact.group ? contact.group.groupName : '-----',
    }));
    const headers = [
        { label: 'First Name', key: 'First Name' },
        { label: 'Last Name', key: 'Last Name' },
        { label: 'Email', key: 'Email' },
        { label: 'Created By', key: 'Created By' },
        { label: 'Group', key: 'Group' },
    ];
    const selectedTableData = selectedRows.map((contact: any) => ({
        'First Name': contact.firstName,
        'Last Name': contact.lastName,
        'Email': contact.email,
        'Created By': contact.createdBy,
        'Group': contact.group ? contact.group.groupName : '-----',
    }));
    const handleRowSelection = (contact: any, contactId: string) => {
        //    create group user id list
        setUserIds((prevGroupIds) => {
            const index = prevGroupIds.indexOf(contactId);
            if (index !== -1) {
                return [...prevGroupIds.slice(0, index), ...prevGroupIds.slice(index + 1)];
            } else {
                return [...prevGroupIds, contactId];
            }
        });

        // checkbox active inactive show
        let tempObj = contactResults;
        for (let i = 0; i < tempObj.length; i++) {
            if (tempObj[i].contactId === contactId) {
                tempObj[i].isChecked = !tempObj[i].isChecked
                break;
            }
        }
        setContacts(tempObj);
        if (selectedRows.includes(contact)) {
            setSelectedRows(selectedRows.filter((id: any) => id !== contact));
        } else {
            setSelectedRows([...selectedRows, contact]);
        }
    };
    const handleAllRowSelection = () => {
        let tempObj = contactResults;
        let tempArr: any = [];
        if (countCheckedItems(contactResults) === contactResults.length) {
            for (let i = 0; i < tempObj.length; i++) {
                tempObj[i].isChecked = false;
                tempArr.push(tempObj[i].contactId);
                //    create group user id list
                setUserIds([]);
            }
        }
        else {
            for (let i = 0; i < tempObj.length; i++) {
                tempObj[i].isChecked = true;
                tempArr.push(tempObj[i].contactId);
                //    create group user id list
                setUserIds((prevGroupIds) => {
                    // Check if contactId already exists in the array
                    if (!prevGroupIds.includes(tempObj[i].contactId)) {
                        return [...prevGroupIds, tempObj[i].contactId];
                    } else {
                        return prevGroupIds;
                    }
                });
            }
        }
        if (countCheckedItems(contactResults) === contactResults.length) {
            setSelectAll(true)
        }
        else {
            setSelectAll(false)
        }
        setSelectedRows([]);
    };


    return (
        <>
            <DashboardNavbar />
            {spinLoading && <Loader />}
            <section className={`fade-in contactPage ${fadeAnimation ? 'visible' : ''}`}>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className="contactBox">
                                <div className="contactTop">
                                    <div className='contactHeading'>
                                        <h1>My Contacts</h1>
                                        <div className="searchName contactSearch">
                                            <input type="text" value={searchTerm} onChange={handleInputChange} className="form-control" id="inputValidation" placeholder="Search Contacts & Groups" />
                                            <FontAwesomeIcon icon={faSearch} />
                                        </div>
                                    </div>
                                    <div className='contactBtnList'>
                                        <button onClick={handleGroupShow}>Create Groups</button>
                                        <button onClick={handleShow}>Add Contacts</button>
                                    </div>
                                </div>
                                <div className="contactListTable">
                                    {contactView === null ? <SmallLoader /> : <><div className="contactFilter">
                                        <button onClick={() => setFilterTag("ALL")} className={filterTag === "ALL" ? "active" : ""}>All</button>
                                        <button onClick={() => setFilterTag("CONTACT")} className={filterTag === "CONTACT" ? "active" : ""}>Contacts</button>
                                        <button onClick={() => setFilterTag("GROUP")} className={filterTag === "GROUP" ? "active" : ""}>Groups</button>
                                    </div>

                                        <Table responsive className='tableBody'>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <label className='tableCheckBox mobileTable'>
                                                            <div className="contactCheck">
                                                                <input type="checkbox" checked={selectAll} onChange={handleAllRowSelection} />
                                                                <span className="checkmark"></span>
                                                            </div>
                                                        </label>
                                                    </th>
                                                    <th onClick={() => sortTable('firstName')}>First Name {firstNameBy === 'firstName' && <FontAwesomeIcon icon={firstNameOrder === 'asc' ? faChevronDown : faChevronUp} />}</th>
                                                    <th onClick={() => sortTable('lastName')}>Last Name {lastNameBy === 'lastName' && <FontAwesomeIcon icon={lastNameOrder === 'asc' ? faChevronDown : faChevronUp} />}</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th onClick={() => sortTable('createdBy')}>Created By {createdBy === 'created' && <FontAwesomeIcon icon={createdOrder === 'asc' ? faChevronDown : faChevronUp} />}</th>
                                                    <th>Group</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {contactResults && contactResults.length ? <>
                                                    {contactResults && contactResults.map((contact: any, i: number) => <tr className='leftTable' key={`contact` + i}>
                                                        <td>
                                                            <label className='tableCheckBox mobileTable'>
                                                                <div className="contactCheck">
                                                                    <input type="checkbox" onChange={() => handleRowSelection(contact, contact.contactId)} checked={contact.isChecked} />
                                                                    <span className="checkmark"></span>
                                                                </div>
                                                            </label>

                                                        </td>
                                                        <td>
                                                            <p className={contact.firstName && contact.firstName.length > 12 ? 'tooltip-top' : ""} data-tooltip={contact.firstName && contact.firstName.length > 12 ? contact.firstName : ""}>
                                                                {contact.firstName && contact.firstName.length > 12 ? contact.firstName.substring(0, 10) + '..' : contact.firstName}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p className={contact.lastName && contact.lastName.length > 12 ? 'tooltip-top' : ""} data-tooltip={contact.lastName && contact.lastName.length > 12 ? contact.lastName : ""}>
                                                                {contact.lastName && contact.lastName.length > 12 ? contact.lastName.substring(0, 10) + '..' : contact.lastName}
                                                            </p>
                                                        </td>
                                                        <td><p className={contact.email && contact.email.length > 15 ? 'tooltip-top' : ""} data-tooltip={contact.email && contact.email.length > 15 ? contact.email : ""}>
                                                            {shortEmail(contact.email)}
                                                        </p></td>
                                                        <td>{contact.phoneNumber && contact.phoneNumber ? `+${contact.phoneNumber}` : '-----'}</td>
                                                        <td>{contact.createdBy && contact.createdBy}</td>
                                                        <td>{contact.group && contact.group ? contact.group.groupName : '-----'}</td>
                                                        <td className='d-flex'>
                                                            <SmallButton style={{ marginRight: '5px' }} customWidth="62" customHeight="24" customBackgroundColor='#FFFFFF' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => editContact(contact.contactId)}>Edit</SmallButton>
                                                            <SmallButton customWidth="62" customHeight="24" customBackgroundColor='#FFFFFF' customBorderColor='#F94A29' customTextColor='#F94A29' onClick={() => deleteContact(contact.contactId)}>Remove</SmallButton>
                                                        </td>
                                                    </tr>)}
                                                </> : <tr>
                                                    <td className='text-center fileEmpty' colSpan={9}>There are no contact available for display</td>
                                                </tr>
                                                }
                                            </tbody>
                                        </Table></>}
                                </div>
                                <div className="contactPagination">
                                    <div className='contactPagList'>
                                        <div className="contactDownload">
                                            {contactResults && contactResults.length ? <CSVLink data={selectedTableData.length > 0 ? selectedTableData : tableData} headers={headers} filename="contacts.csv">
                                                <button>Export</button>
                                            </CSVLink> : ""}

                                            <button onClick={handleExportShow}>Import</button>
                                        </div>
                                        {contactResults && contactResults.length > 0 ? <>
                                            <div className='w-100 paginationBottom'>
                                                <Pagination showResult={showResult} pageResult={pageResult} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} resultLength={resultLength} />
                                            </div>
                                        </> : ""}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <AddContact groupName={groupName} groupIdentifier={groupIdentifier} show={show} setShow={setShow} handleClose={handleClose} setSpinLoading={setSpinLoading} count={count} setCount={setCount} setGroupListShow={setGroupListShow} />
                <CreateGroup userIds={userIds} count={count} setCount={setCount} groupShow={groupShow} setGroupShow={setGroupShow} handleGroupClose={handleGroupClose} />
                <GroupList groupName={groupName} setGroupName={setGroupName} count={count} setGroupID={setGroupID} groupListShow={groupListShow} handleGroupListClose={handleGroupListClose} />
                <EditContact phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} contactInfo={contactInfo} editShow={editShow} setEditShow={setEditShow} handleEditClose={handleEditClose} count={count} setCount={setCount} />
                <ExportFile count={count} setCount={setCount} exportShow={exportShow} setExportShow={setExportShow} handleExportClose={handleExportClose} />
                <SingleContact editContact={editContact} contactInfo={contactInfo} contactShow={contactShow} handleContactClose={handleContactClose} />
            </section>
        </>
    )
}

export default MyContact