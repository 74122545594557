import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import apple from '../../assets/banner/apple.png';
import { TypeAnimation } from 'react-type-animation';
import { isAuthenticate } from '../../api/auth';
import { Link } from 'react-router-dom';

const Banner = () => {
    return (
        <>
            <section id='/home' className="bannerSection">
                <Container>
                    <Row>
                        <Col md={7}>
                            <div className="leftBanner">
                                <h1>Seamlessly streamline and securely manage and handle document transfers for <br /> <TypeAnimation
                                    sequence={[
                                        'all industries',
                                        1000,
                                        'financial institutions',
                                        2000,
                                        'accountants',
                                        3000,
                                        'all brokers',
                                        4000,
                                        'consultants',
                                        5000,
                                        'real estate',
                                        () => { }
                                    ]}
                                    cursor={true}
                                    repeat={Infinity}
                                /></h1>
                                <p>Our platform maximizes efficiencies by providing the ultimate solution for secure, fast, and easy document transfers designed to meet the requirements of all industries.</p>

                                {isAuthenticate() && isAuthenticate() ?
                                    <>
                                        <Link to="/dashboard" className='getStarted'>Get Started</Link>
                                    </> :
                                    <>
                                        <Link to="/login" className='getStarted'>Get Started</Link>
                                    </>
                                }

                            </div>
                        </Col>
                        <Col md={5}>
                            <div className="rightBanner">
                                <img src={apple} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Banner