import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import bannerImage from "../../assets/banner/whyDocoteam.png"
import { isAuthenticate } from '../../api/auth';
import { Link } from 'react-router-dom';
import youTube from "../../assets/banner/youtube.png";

const WhyBanner = () => {
    return (
        <section className='whyBannerSection'>
            <Container>
                <Row>
                    <Col md={5} className='d-flex align-items-center'>
                        <div className="whyBannerContent">
                            <h1>Docoteam vs. Email?</h1>
                            <p>Files attached to emails can be vulnerable to hacking, unauthorized access, or loss. Email attachments can become scattered across different email threads, making it difficult to keep track of the latest version or locate a specific file. With Docoteam, you can say goodbye to the risks and inefficiencies of email attachments and hello to secure and organized file sharing. Welcome to Docoteam.</p>
                            {isAuthenticate() && isAuthenticate() ?
                                <>
                                    <Link to="/dashboard" className='getStarted'>Get Started</Link>
                                </> :
                                <>
                                    <Link to="/login" className='getStarted'>Get Started</Link>
                                </>
                            }
                        </div>
                    </Col>
                    <Col md={7}>
                        <div className='whyBannerImage'>
                            <img src={bannerImage} alt="banner" />
                            <img className='youTube' src={youTube} alt="youTube" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default WhyBanner