import React, { useState } from 'react';
import moment from 'moment';
import { countRequiredFile, countTotalFileOptional, countTotalFileUploaded, getCookieValue, sumAttachmentSizeInMB, tooltipFullName, transformFullName } from '../CommonInterface/CommonFunction';
import { CreateRequestInterface } from '../CommonInterface/CommonInterface';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { permanentlyDeleteRequest } from '../../api/auth';
import { showNotifications } from '../../helpers/toaster';
import WarningModal from '../CommonCompoment/WarningModal/WarningModal';
// google font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { reminderSend, restoreDelete } from '../../api/files';

interface RequestFileProps {
    allRequestList: CreateRequestInterface["body"]["request"];
    InformationApi: (value: any) => void;
    singleTable: (value: string) => void;
    editRequest: (value: string) => void;
    itemDownload: (value: any) => void;
    allCheck: boolean;
    deleteCount: number;
    setDeleteCount: (value: number) => void;
    setAllRequestList: (value: CreateRequestInterface["body"]["request"]) => void;
    checkAllSelect: () => void;
    filterTag: string;
}

const RequestTable = ({ filterTag, checkAllSelect, setAllRequestList, setDeleteCount, allCheck, deleteCount, allRequestList, itemDownload, InformationApi, singleTable, editRequest }: RequestFileProps) => {
    const token = JSON.parse(getCookieValue('docoteam') || '{}');
    const [requestId, setRequestId] = useState("");
    const [warningShow, setWarningShow] = useState(false);
    const handleWarningClose = () => {
        setWarningShow(false);
    }
    const headingText = "This will permanently delete all of your file";
    const paragraphText = "**You won't be able to recover any file";

    const [tableName, setTableName] = useState(false);
    const [tableRecipient, setTableRecipient] = useState(false);
    const [tableRequester, setTableRequester] = useState(false);
    const [tableRequestType, setTableRequestType] = useState(false);
    const [tableCreated, setTableCreated] = useState(false);
    const [tableDue, setTableDue] = useState(false);
    const [tableUpdated, setTableUpdated] = useState(false);
    const [tableProgress, setTableProgress] = useState(false);
    const [sortBy, setSortBy] = useState(null);


    const handleWarningShow = (requestId: string) => {
        setRequestId(requestId);
        setWarningShow(true);
    }

    // delete request files
    const deleRequestFiles = () => {
        permanentlyDeleteRequest(requestId).then((data) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message)
            }
            else {
                showNotifications('success', "Successfully delete your files")
                setWarningShow(false);
                setDeleteCount(deleteCount + 1);
            }
        })
    }

    // sorting table data
    const sortData = (columnName: any) => {
        if (columnName === "title") {
            setTableName(!tableName)
        }
        if (columnName === "recipient") {
            setTableRecipient(!tableRecipient)
        }
        if (columnName === "requester") {
            setTableRequester(!tableRequester)
        }
        if (columnName === "requestType") {
            setTableRequestType(!tableRequestType)
        }
        if (columnName === "createdAt") {
            setTableCreated(!tableCreated)
        }
        if (columnName === "settings") {
            setTableDue(!tableDue)
        }
        if (columnName === "updatedAt") {
            setTableUpdated(!tableUpdated)
        }
        if (columnName === "progress") {
            setTableProgress(!tableProgress)
        }

        let sorted = [...allRequestList];
        if (sortBy === columnName) {
            sorted.reverse();
        } else {
            sorted.sort((a: any, b: any) => {
                if (a[columnName] < b[columnName]) return -1;
                if (a[columnName] > b[columnName]) return 1;
                return 0;
            });
        }
        setAllRequestList(sorted);

        setSortBy(columnName);
    };

    const reminderApi = (requestId: string) => {
        reminderSend(requestId).then((data) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message)
            }
            else {
                showNotifications('success', "Successfully send reminder");
            }
        })
    }

    const RestoreFile = (sendId: any) => {
        restoreDelete('requests', sendId).then(async (data: any) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message)
            }
            else {
                showNotifications('success', "Successfully restore your files")
                setDeleteCount(deleteCount + 1);
            }
        });
    };

    return (
        <>
            <ToastContainer />
            <Table responsive className='tableBody dashboardTable'>
                <thead>
                    <tr>
                        <th className='tableMark'>
                            <label className='tableCheckBox mobileTable'>
                                <div className="contactCheck">
                                    <input type="checkbox" onClick={checkAllSelect} checked={allCheck} onChange={() => { }} />
                                    <span className="checkmark"></span>
                                </div>
                            </label>
                        </th>
                        <th onClick={() => sortData("title")}>Request Name {tableName ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("recipient")} >Recipient(s) {tableRecipient ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("requester")} >Requester {tableRequester ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("requestType")} >Request type {tableRequestType ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("createdAt")} >Created {tableCreated ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("settings")} >Due  {tableDue ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("updatedAt")} >Updated {tableUpdated ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th>Size </th>
                        <th onClick={() => sortData("progress")} >Progress {tableProgress ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {allRequestList && allRequestList.length ? <>
                        {allRequestList && allRequestList.map((data, index: number) => <tr key={`request` + index} className='dashboardItem'>
                            <td className=''>
                                <label className='tableCheckBox'>
                                    <div className="contactCheck">
                                        <input type="checkbox" onClick={() => singleTable(data.requestId)} checked={data.isChecked} onChange={() => { }} />
                                        <span className="checkmark"></span>
                                    </div>
                                </label>
                            </td>
                            <td className='infoLink' onClick={() => InformationApi(data.requestId)}>
                                <p >
                                    {data.debouncedInfo ? <span className='tooltip-top' data-tooltip="Don't get the mail"><FontAwesomeIcon icon={faTriangleExclamation} /></span> : ""}
                                    <span className={data.title && data.title.length > 12 ? 'tooltip-top' : ""} data-tooltip={data.title && data.title.length > 12 ? data.title : ""}>{data.title && data.title.length > 12 ? data.title.substring(0, 10) + '..' : data.title}</span>
                                </p>
                            </td>
                            <td className='infoLink' onClick={() => InformationApi(data.requestId)}>
                                <p className='tooltip-top' data-tooltip={data.recipients && data.recipients.users ? tooltipFullName(data.recipients.users) : ""}>
                                    {data.recipients && data.recipients.users && transformFullName(data.recipients.users)}
                                </p>
                            </td>
                            <td className='infoLink' onClick={() => InformationApi(data.requestId)}>
                                <p className={token.user.firstName ? 'tooltip-top' : ""} data-tooltip={token.user.firstName ? `${token.user.firstName} ${token.user.lastName}` : ""}>
                                    {token.user.firstName && token.user.firstName.length > 12 ? token.user.firstName.substring(0, 10) + '..' : token.user.firstName}
                                </p>
                            </td>
                            <td className='infoLink' onClick={() => InformationApi(data.requestId)}>{data.recipients.senderType && data.recipients.senderType}</td>
                            <td className='infoLink' onClick={() => InformationApi(data.requestId)}>{moment(data.createdAt).format('MMM DD')}</td>
                            <td className='infoLink' onClick={() => InformationApi(data.requestId)}>{data.settings.dueDate ? moment(data.settings.dueDate).format('MMM DD') : "N/A"}</td>
                            <td className='infoLink' onClick={() => InformationApi(data.requestId)}>{moment(data.updatedAt).format('MMM DD')}</td>
                            <td className='infoLink' onClick={() => InformationApi(data.requestId)}>{sumAttachmentSizeInMB(data.items)}</td>
                            {countRequiredFile(data.items) > 0 ? <>{countTotalFileUploaded(data.items) <= 25 ? <td className='process infoLink' onClick={() => InformationApi(data.requestId)}><div className='tooltip-top' data-tooltip={`The % value is calculated exclusively\nbased on the required file.`} style={{ width: '40px', height: '40px' }}><CircularProgressbar styles={buildStyles({ textSize: '24px', pathColor: `#666464`, textColor: '#666464' })} strokeWidth={12} value={countTotalFileUploaded(data.items)} text={`${countTotalFileUploaded(data.items)}%`} /></div></td> :
                                countTotalFileUploaded(data.items) <= 50 ? <td className='process infoLink' onClick={() => InformationApi(data.requestId)}><div className='tooltip-top' data-tooltip={`The % value is calculated exclusively\nbased on the required file.`} style={{ width: '40px', height: '40px' }}><CircularProgressbar styles={buildStyles({ textSize: '24px', pathColor: `#EF682D`, textColor: '#EF682D' })} strokeWidth={12} value={countTotalFileUploaded(data.items)} text={`${countTotalFileUploaded(data.items)}%`} /></div></td> :
                                    countTotalFileUploaded(data.items) <= 75 ? <td className='process infoLink' onClick={() => InformationApi(data.requestId)}><div className='tooltip-top' data-tooltip={`The % value is calculated exclusively\nbased on the required file.`} style={{ width: '40px', height: '40px' }}><CircularProgressbar styles={buildStyles({ textSize: '24px', pathColor: `#6AD54E`, textColor: '#6AD54E' })} strokeWidth={12} value={countTotalFileUploaded(data.items)} text={`${countTotalFileUploaded(data.items)}%`} /></div></td> :
                                        countTotalFileUploaded(data.items) <= 100 ? <td className='process infoLink' onClick={() => InformationApi(data.requestId)}><div className='tooltip-top' data-tooltip={`The % value is calculated exclusively\nbased on the required file.`} style={{ width: '40px', height: '40px' }}><CircularProgressbar styles={buildStyles({ textSize: '24px', pathColor: `#1B6D2D`, textColor: '#1B6D2D' })} strokeWidth={12} value={countTotalFileUploaded(data.items)} text={`${countTotalFileUploaded(data.items)}%`} /></div></td> :
                                            <></>
                            }</> : <>{countTotalFileOptional(data.items) <= 25 ? <td className='process infoLink' onClick={() => InformationApi(data.requestId)}><div className='tooltip-top' data-tooltip={`The % value is calculated exclusively\nbased on the optional file.`} style={{ width: '40px', height: '40px' }}><CircularProgressbar styles={buildStyles({ textSize: '24px', pathColor: `#666464`, textColor: '#666464' })} strokeWidth={12} value={countTotalFileOptional(data.items)} text={`${countTotalFileOptional(data.items)}%`} /></div></td> :
                                countTotalFileOptional(data.items) <= 50 ? <td className='process infoLink' onClick={() => InformationApi(data.requestId)}><div className='tooltip-top' data-tooltip={`The % value is calculated exclusively\nbased on the optional file.`} style={{ width: '40px', height: '40px' }}><CircularProgressbar styles={buildStyles({ textSize: '24px', pathColor: `#EF682D`, textColor: '#EF682D' })} strokeWidth={12} value={countTotalFileOptional(data.items)} text={`${countTotalFileOptional(data.items)}%`} /></div></td> :
                                    countTotalFileOptional(data.items) <= 75 ? <td className='process infoLink' onClick={() => InformationApi(data.requestId)}><div className='tooltip-top' data-tooltip={`The % value is calculated exclusively\nbased on the optional file.`} style={{ width: '40px', height: '40px' }}><CircularProgressbar styles={buildStyles({ textSize: '24px', pathColor: `#6AD54E`, textColor: '#6AD54E' })} strokeWidth={12} value={countTotalFileOptional(data.items)} text={`${countTotalFileOptional(data.items)}%`} /></div></td> :
                                        countTotalFileOptional(data.items) <= 100 ? <td className='process infoLink' onClick={() => InformationApi(data.requestId)}><div className='tooltip-top' data-tooltip={`The % value is calculated exclusively\nbased on the optional file.`} style={{ width: '40px', height: '40px' }}><CircularProgressbar styles={buildStyles({ textSize: '24px', pathColor: `#1B6D2D`, textColor: '#1B6D2D' })} strokeWidth={12} value={countTotalFileOptional(data.items)} text={`${countTotalFileOptional(data.items)}%`} /></div></td> :
                                            <></>
                            }</>}
                            <td className='download'>
                                {data.isMarkedForDeletion === true ?
                                    <button className='editBtn' onClick={() => handleWarningShow(data.requestId)}>Delete</button>
                                    : <button className='editBtn' onClick={() => editRequest(data.requestId)}>Edit</button>
                                }
                                <button onClick={() => itemDownload(data)}>Download</button>
                                {filterTag === "delete" ? <button style={{ marginLeft: '10px' }} onClick={() => RestoreFile(data.requestId)}>Restore</button>
                                    : <>{countTotalFileUploaded(data.items) === 100 ? "" : <button className='reminderBtn' onClick={() => reminderApi(data.requestId)}>Remind</button>}</>}
                            </td>
                        </tr>
                        )}
                    </>
                        : <tr>
                            <td className='text-center fileEmpty' colSpan={10}>There are no request available for display</td>
                        </tr>}
                </tbody>
            </Table >
            <WarningModal headingText={headingText} paragraphText={paragraphText} deleteEmployee={deleRequestFiles} handleWarningClose={handleWarningClose} warningShow={warningShow} />
        </>
    )
}

export default RequestTable