
import React, { forwardRef } from 'react';
import calenderIcon from "../../assets/icon/calender.png";
import { PackageInterface, RecipientUser } from './CommonInterface';
import moment from 'moment';

// cookies value
export const getCookieValue = (name: any) => {
    const cookies = document.cookie.split('; ');
    const cookie = cookies.find((c) => c.startsWith(`${name}=`));
    return cookie ? decodeURIComponent(cookie.split('=')[1]) : null;
};
// set cookies
export const setCookie = (name: any, value: any) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 2 * 60 * 60 * 1000);
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires.toUTCString()}; path=/`;
};
// remove cookies
export const removeCookie = (name: any) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
// recipient name transform
export function transformFullName(users: any) {
    return users.map((user: any) => {
        const fullName = user.fullName;
        const shortenedFullName = fullName.length > 5 ? fullName.substring(0, 5) + '..' : fullName;
        return shortenedFullName;
    }).join(', ');;
}

// dashboard tooltip
export function tooltipFullName(users: any) {
    return users.map((user: any) => {
        return (user.fullName);
    }).join(', ');
}

// dashboard file size
// export function sumAttachmentSizeInMB(arr: any[]) {
//     const totalBytes = arr.reduce((acc) => {
//         for (let i = 0; i < acc.files.length; i++) {
//                 return acc + acc.files[i].size;
//         }
//         return acc;
//     }, 0);
//     const totalMB = totalBytes / (1024 * 1024);
//     if (totalMB < 0.50) {
//         return (totalMB * 1024).toFixed(2) + " KB";
//     }
//     else {
//         return totalMB.toFixed(2) + " MB";
//     }
// }

export function sumFileSize(files: any) {
    let totalSize = 0;

    const totalBytes = files.forEach((file: any) => {
        return totalSize += file.size;
    });


    const totalMB = totalSize / (1024 * 1024);
    if (totalMB < 0.50) {
        return (totalMB * 1024).toFixed(2) + " KB";
    }
    else {
        return totalMB.toFixed(2) + " MB";
    }
}

// sent all file download zip
// const sentAllDownloadFile = async (fileInfo: any) => {
//     setSpinLoading(true)
//     const urls: string[] = []
//     for (let i = 0; i < fileInfo.files.length; i++) {
//         const response = await filesDownloadApi(fileInfo.files[i].fileId);
//         if (!response.body || !response.body.downloadUrl) {
//             throw new Error("Invalid response from server");
//         }
//         urls.push(response.body.downloadUrl);
//     }
//     if (urls.length === 0) {
//         setSpinLoading(false)
//     }
//     else {
//         downloadFilesAsZip(urls, fileInfo.sendId, setSpinLoading)
//     }
// }



export function shortEmail(email: string) {
    // Split the email into username and domain parts
    var parts = email.split('@');

    // Check if the username part has more than 5 characters
    if (parts[0].length > 5) {
        // Shorten the username part to 5 characters followed by two dots
        var shortenedUsername = parts[0].slice(0, 5) + '..';
        // Reconstruct the email address with the shortened username
        var shortenedEmail = shortenedUsername + '@' + parts[1];
        return shortenedEmail;
    } else {
        // If the username has 5 or fewer characters, return the original email
        return email;
    }
}

export function countCheckedItems(arr: any) {
    let count = 0;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].isChecked === true) {
            count++;
        }
    }
    return count;
}

export function sumAttachmentSizeInMB(arr: any[]) {
    const totalBytes = arr.reduce((acc, curr) => {
        for (let i = 0; i < curr.files.length; i++) {
            if (curr.files[i] && curr.files[i].size) {
                return acc + curr.files[i].size;
            }
        }
        return acc;
    }, 0);
    const totalMB = totalBytes / (1024 * 1024);
    if (totalMB < 0.50) {
        return (totalMB * 1024).toFixed(2) + " KB";
    }
    else {
        return totalMB.toFixed(2) + " MB";
    }
}

export function countRequiredFile(arr: any[]): number {
    let totalRequired = 0;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].isRequired === true) {
            totalRequired++
        }
    }
    return totalRequired
}
// progress number count

export function countTotalFileUploaded(arr: any[]): number {
    let count = 0;
    let totalRequired = countRequiredFile(arr)
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].files.length > 0 && arr[i].isRequired === true) {
            count++;
        }
    }
    const progressValue = ((count / totalRequired) * 100).toFixed(2);
    return (parseInt(progressValue));
}

// optional file count
export function countOptionalFile(arr: any[]): number {
    let totalRequired = 0;
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].isRequired === false) {
            totalRequired++
        }
    }
    return totalRequired
}
// progress number count
export function countTotalFileOptional(arr: any[]): number {
    let count = 0;
    let totalRequired = countOptionalFile(arr)
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].files.length > 0 && arr[i].isRequired === false) {
            count++;
        }
    }
    const progressValue = ((count / totalRequired) * 100).toFixed(2);
    return (parseInt(progressValue));
}
// pagination divided
export function multiplyBySixAndShowSeries(originalValue: number) {
    if (originalValue <= 6) {
        return [originalValue];
    }

    const resultArray = Array.from({ length: Math.ceil(originalValue / 6) }, (_, index) => {
        const calculatedValue = 6 * (index + 1);
        return calculatedValue <= originalValue ? calculatedValue : originalValue;
    });

    return resultArray;
}
export function fileSize(size: number) {
    const bytes = size / (1024 * 1024);
    if (bytes < 0.50) {
        return (bytes * 1024).toFixed(2) + " KB";
    }
    else {
        return bytes.toFixed(2) + " MB";
    }
}

// calender
export const CustomDatePickerInput: React.FC<any> = forwardRef(({ value, onClick }, ref) => (
    <button className="calenderBox requestInputForm" onClick={onClick}>
        {value}
        <img src={calenderIcon} alt="calender" />
    </button>
));
export const CustomDateFormatInput: React.FC<any> = forwardRef(({ value, onClick }, ref) => (
    <button className="calenderBox requestInputForm" onClick={onClick}>
        {value}
        <p>MM/DD/YYYY</p>
        <img src={calenderIcon} alt="calender" />
    </button>
));
// convert in GB
export const convertGB = (num: string) => {
    const numBytes = parseFloat(num);
    if (isNaN(numBytes)) {
        return 'Invalid input';
    }

    const megabyte = 1024 * 1024;
    const gigabyte = 1024 * 1024 * 1024;

    const mb = Math.round(numBytes / megabyte);
    const gb = Math.round(numBytes / gigabyte);

    if (numBytes >= gigabyte) {
        return `${gb} GB`;
    }

    return `${mb} MB`;
};

export const convertMBToGB = (bytes: number) => {
    const megabyte = 1024 * 1024;
    const gigabyte = megabyte * 1024;

    if (bytes < gigabyte) {
        const mbValue = bytes / megabyte;
        return `${mbValue.toFixed(2)} MB`;
    } else {
        const gbValue = bytes / gigabyte;
        return `${gbValue.toFixed(2)} GB`;
    }
};

export function getPackageIdByName(packageArray: PackageInterface[], nameToFind: string) {
    for (let i = 0; i < packageArray.length; i++) {
        if (packageArray[i].name === nameToFind) {
            return packageArray[i].packageId;
        }
    }
    return null;
}

// recipient remove
export function fileRemove(recipientsValue: RecipientUser[], name: string, setRecipientsValue: React.Dispatch<React.SetStateAction<RecipientUser[]>>) {
    const indexToDelete = recipientsValue.findIndex((item: any) => item.email === name);
    if (indexToDelete !== -1) {
        recipientsValue.splice(indexToDelete, 1);
    }
    setRecipientsValue([...recipientsValue]);
    // const storedItems = JSON.parse(getCookieValue('contactId') || '[]');
    // const updatedItems = storedItems.filter((item: any) => item !== name);
    // setCookie('contactId', JSON.stringify(updatedItems));
}
// isValidDate
export function isValidDate(dateString: any) {
    const date = new Date(dateString);
    const now = new Date();
    if (isNaN(date.getTime())) {
        return 'emptyDate';
    }
    const date1 = moment(date).format('YYYY-MM-DD')
    const date2 = moment(now).format('YYYY-MM-DD')
    const date11 = new Date(date1);
    const date22 = new Date(date2);
    if (date11.getTime() === date22.getTime()) {
        return false;
    } else if (date11 < date22) {
        return false;
    } else {
        return true;
    }
}

// payment package
export const getPriceByName = (packagePrice: PackageInterface[] | undefined, packageName: string, subtractValue: number, month: number) => {
    const packageItem = packagePrice?.find(pkg => pkg.name === packageName);
    if (packageItem && typeof packageItem.price === 'number') {
        if (!isNaN(packageItem.price)) {
            const result = month * (packageItem.price / 100) - month * subtractValue;
            return result.toString();
        }
    }
    return null;
};

export const handleForbiddenError = (navigate: any) => {
    removeCookie('docoteam');
    navigate("/login");
};

export const decodeJwt = (token: string) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join('')
        );

        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error('Error decoding JWT token:', error);
        return null;
    }
};


export const calculateDaysRemaining = (createdAt: any) => {
    const now: any = new Date();
    const createdDate: any = new Date(createdAt);
    const expireDate: any = new Date(createdDate);
    expireDate.setDate(expireDate.getDate() + 7);
    const diffTime = expireDate - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays - 1;
};
