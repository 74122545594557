import React, { useState, useEffect } from 'react'
import DashboardNavbar from '../CommonCompoment/DashboardNavbar/DashboardNavbar'
import { Col, Container, Row, ProgressBar, Tabs, Tab } from 'react-bootstrap'
import { singleUser } from '../../api/auth';
import { PackageInterface, UserDataInterface } from '../CommonInterface/CommonInterface';
import PriceTable from '../Pricing/PriceTable';
import { getAllPackage } from '../../api/package';
import PricePlan from '../CommonCompoment/PricePlan/PricePlan';
import CardPayment from '../CommonCompoment/CardPayment/CardPayment';
import { changeBilling } from '../../api/payment';
import { convertGB, convertMBToGB, getCookieValue, getPackageIdByName, getPriceByName, handleForbiddenError, setCookie } from '../CommonInterface/CommonFunction';
import Loader from '../CommonCompoment/Loader/Loader';
import { useNavigate } from 'react-router-dom';

const ChangeBilling = () => {
    const [fadeAnimation, setFadeAnimation] = useState(false);
    const [userInfo, setUserInfo] = useState<UserDataInterface>();
    const navigate = useNavigate();
    const [packagePrice, setPackagePrice] = useState<PackageInterface[] | undefined>();
    const token = JSON.parse(getCookieValue('docoteam') || '{}');
    const [billingType, setBillingType] = useState("Monthly");
    const [spinLoading, setSpinLoading] = useState(false);
    const [storage, setStorage] = useState(0);
    const [activeStep, setActiveStep] = React.useState(1);
    const [billingPayment, setBillingPayment] = useState<boolean>(true);
    const storedPaymentPlan = JSON.parse(getCookieValue('choosePlan') || '{}');
    const { user } = token;

    useEffect(() => {
        setFadeAnimation(true);
        singleUser(user.userId).then((data) => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
            }
            else {
                setUserInfo(data.user);
                setStorage(data.user.availableStorage);
            }
        });
        getAllPackage().then((data) => {
            setPackagePrice(data.packages);
        });

    }, []);


    const choosePlan = (plan: string) => {
        setBillingType(plan)
        if (storedPaymentPlan) {
            storedPaymentPlan.billingType = plan;
            setCookie('choosePlan', JSON.stringify(storedPaymentPlan));
        }
    }

    const selectChoosePlan = (plan: string) => {
        if (storedPaymentPlan) {
            storedPaymentPlan.price = plan;
            storedPaymentPlan.billingType = billingType;
            setCookie('choosePlan', JSON.stringify(storedPaymentPlan));
        }
        setSpinLoading(true)
        getAllPackage().then((data) => {
            if (data.statusCode === 200) {
                const planPackage = JSON.parse(getCookieValue('choosePlan') || '{}');
                if (planPackage !== null) {
                    const packageId = getPackageIdByName(data.packages, planPackage.price);
                    let packageInfo = {
                        "packageId": packageId,
                        "billingType": billingType
                    }
                    changeBilling(packageInfo).then((data) => {
                        if (data.statusCode !== 200) {
                            console.log('error', data);
                        }
                        else {
                            const userInfo = JSON.parse(getCookieValue('docoteam') || '{}');
                            const updatedData = JSON.stringify({ ...userInfo, ...data });
                            setCookie('docoteam', updatedData);
                            setBillingPayment(false);
                        }
                        setSpinLoading(false)
                    });

                }
            }
        })
    }

    return (
        <>
            <DashboardNavbar />
            {spinLoading &&
                <Loader />
            }
            <section className={`fade-in adminPage ${fadeAnimation ? 'visible' : ''}`}>
                <Container fluid>
                    {billingPayment ? <>
                        <Row>
                            <Col md={12}>
                                <div className="adminHeading">
                                    <h2>{userInfo && userInfo.companyName}</h2>
                                    <h2>Billing Setting</h2>
                                </div>
                            </Col>
                            {userInfo && userInfo.package ? <>
                                <Col md={6}>
                                    <div className="currentPlan">
                                        <div className="planHeading">
                                            <h2>Current Plan</h2>
                                        </div>
                                        <div className="planList">
                                            <div className="planDescription">
                                                <p>Plan Type</p>
                                                <div className='planName'>{userInfo && userInfo.package.packageName}</div>
                                            </div>
                                            <div className="planDescription">
                                                <p>Billing Type</p>
                                                <div className='planName'>{userInfo && userInfo.package.billingType}</div>
                                            </div>
                                            <div className="planDescription">
                                                <p>Price</p>
                                                <div className='planName'>{userInfo && userInfo.package.paymentAmount / 100}$/{userInfo && userInfo.package.billingType}/user</div>
                                            </div>
                                        </div>
                                    </div>
                                </Col></> : <>
                                <Col md={6}>
                                    <div className="currentPlan">
                                        <div className="planHeading">
                                            <h2>User Profile</h2>
                                        </div>
                                        <div className="planList">
                                            <div className="planDescription">
                                                <p>Email: </p>
                                                <div className='planName'>{userInfo && userInfo.email}</div>
                                            </div>
                                            <div className="planDescription">
                                                <p>First Name</p>
                                                <div className='planName'>{userInfo && userInfo.firstName}</div>
                                            </div>
                                            <div className="planDescription">
                                                <p>Last Name</p>
                                                <div className='planName'>{userInfo && userInfo.lastName}</div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </>}
                            <Col md={6}>
                                <div className="storagePlan">
                                    <div className="planHeading">
                                        <h2>Current Plan</h2>
                                    </div>
                                    <div className="storageProgress">
                                        <p>Usage</p>
                                        <ProgressBar now={userInfo && userInfo.occupiedStorage / parseInt(userInfo.totalStorage) * 100} variant="custom-progress-bar-progress" className="custom-progress-bar-progress" />
                                        <h5>{userInfo && convertMBToGB(userInfo.occupiedStorage)} used of {userInfo && convertGB(userInfo.totalStorage)}</h5>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="userTableBox">
                                    <div className="userHeading">
                                        <h1>Upgrade the plan</h1>
                                    </div>
                                    <div className="coreFeature">
                                        <Tabs
                                            defaultActiveKey="monthly"
                                            id="uncontrolled-tab-example"
                                            className="priceTab"
                                            onSelect={(key: string | null) => {
                                                if (key) {
                                                    choosePlan(key === 'monthly' ? 'Monthly' : 'Annually');
                                                }
                                            }}
                                        >
                                            <Tab className='monthly' eventKey="monthly" title="Monthly">
                                                <Row>
                                                    <PriceTable selectChoosePlan={selectChoosePlan} packagePrice={packagePrice} billingType={billingType} standardMin={getPriceByName(packagePrice, 'STANDARD', 0, 1)} standardMax="" plusMin={getPriceByName(packagePrice, 'PLUS', 0, 1)} plusMax="" />
                                                </Row>
                                            </Tab>
                                            <Tab className='yearly' eventKey="yearly" title="Yearly">
                                                <Row>
                                                    <PriceTable selectChoosePlan={selectChoosePlan} packagePrice={packagePrice} billingType={billingType} standardMin={getPriceByName(packagePrice, 'STANDARD', 5, 1)} standardMax={getPriceByName(packagePrice, 'STANDARD', 0, 1)} plusMin={getPriceByName(packagePrice, 'PLUS', 5, 1)} plusMax={getPriceByName(packagePrice, 'PLUS', 0, 1)} />
                                                </Row>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </> : <>
                        <Row>
                            <div className='d-flex justify-content-center flex-wrap'>
                                <div>
                                    <CardPayment activeStep={activeStep} setActiveStep={setActiveStep} />
                                </div>
                                <div>
                                    <PricePlan />
                                </div>
                            </div>
                        </Row>
                    </>}
                </Container>
            </section>
        </>
    )
}

export default ChangeBilling