import React, { useState, useEffect } from 'react';
import DashboardNavbar from '../CommonCompoment/DashboardNavbar/DashboardNavbar';
import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { singleUser } from '../../api/auth';
import { convertGB, convertMBToGB, getCookieValue, handleForbiddenError } from '../CommonInterface/CommonFunction';
import { UserDataInterface } from '../CommonInterface/CommonInterface';
import { Link, useNavigate } from 'react-router-dom';
import EditUser from '../Admin/EditUser';
import { REQUEST_FOR_MORE_STORAGE_GOOGLE_FROM_URL } from '../../config';
import Loader from '../CommonCompoment/Loader/Loader';
import UpgradePlan from '../CommonCompoment/UpgradePlan/UpgradePlan';

const Profile = () => {
    const [fadeAnimation, setFadeAnimation] = useState(false);
    const [userInfo, setUserInfo] = useState<UserDataInterface>();
    const [editShow, setEditShow] = useState(false);
    const handleEditClose = () => setEditShow(false);
    const [warningShow, setWarningShow] = useState(false);
    const token = JSON.parse(getCookieValue('docoteam') || '{}');

    const [upgradeShow, setUpgradeShow] = useState(false);
    const handleUpgradeClose = () => setUpgradeShow(false);
    const [updatePayment, setUpdatePayment] = useState(false);
    const { user } = token;
    const navigate = useNavigate();
    useEffect(() => {
        setFadeAnimation(true);
        singleUser(user.userId).then((data) => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
            }
            else {
                setUserInfo(data.user);
            }
        });

    }, [editShow]);

    // update profile
    const updateContact = () => {
        setEditShow(true);
    };
    return (
        <>
            <DashboardNavbar />
            <section className={`fade-in adminPage ${fadeAnimation ? 'visible' : ''}`}>
                {userInfo ? <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className="adminHeading">
                                <h2>{userInfo && userInfo.companyName}</h2>
                                <h2>Account Profile</h2>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="currentPlan">
                                <div className="planHeading">
                                    <h2>User Profile</h2>
                                    <button onClick={() => updateContact()}>Update</button>
                                </div>
                                <div className="planList">
                                    <div className="planDescription">
                                        <p>Email </p>
                                        <div className='planName'>{userInfo && userInfo.email}</div>
                                    </div>
                                    <div className="planDescription">
                                        <p>First Name</p>
                                        <div className='planName'>{userInfo && userInfo.firstName}</div>
                                    </div>
                                    <div className="planDescription">
                                        <p>Last Name</p>
                                        <div className='planName'>{userInfo && userInfo.lastName}</div>
                                    </div>
                                    {userInfo && userInfo.telephone ?
                                        <div className="planDescription">
                                            <p>Mobile</p>
                                            <div className='planName'>{userInfo && userInfo.telephone}</div>
                                        </div> : ""}

                                    {userInfo && userInfo.accessLocation ? <>
                                        <div className="planDescription">
                                            <p>Last access</p>
                                            <div className='planName'>{userInfo && userInfo.accessLocation}</div>
                                        </div>
                                    </> : ""}

                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="storagePlan">
                                <div className="planHeading">
                                    <h2>Current Plan</h2>
                                    {user && user.package ? "" : <button onClick={() => setUpgradeShow(true)}>Change Billing/Plan</button>}
                                </div>
                                <div className="storageProgress">
                                    <p>Usage</p>
                                    <ProgressBar now={userInfo && userInfo.occupiedStorage / parseInt(userInfo.totalStorage) * 100} variant="custom-progress-bar-progress" className="custom-progress-bar-progress" />
                                    <h5>{userInfo && convertMBToGB(userInfo.occupiedStorage)} used of {userInfo && convertGB(userInfo.totalStorage)}</h5>
                                    {userInfo && userInfo.package ? <Link to={`${REQUEST_FOR_MORE_STORAGE_GOOGLE_FROM_URL}`} target='_blank'>Request more storage</Link> : ""}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                    : <Loader />}

            </section>

            {/* edit modal */}
            <><EditUser warningShow={warningShow} setWarningShow={setWarningShow} contactInfo={userInfo} editShow={editShow} setEditShow={setEditShow} handleEditClose={handleEditClose} /></>
            <UpgradePlan setUpdatePayment={setUpdatePayment} upgradeShow={upgradeShow} setUpgradeShow={setUpgradeShow} handleUpgradeClose={handleUpgradeClose} />
        </>
    )
}

export default Profile