import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../CommonCompoment/DashboardNavbar/DashboardNavbar'
import './Dashboard.css'
import { Col, Container, Row, Modal } from 'react-bootstrap';
import DashboardPage from '../../styleComponent/DashboardPage/DashboardPage';

import moment from 'moment'
// google font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import 'react-circular-progressbar/dist/styles.css';
import { allRequests, deleteRequestsApi, loadTemplate, deleteTemplateApi, filesDownloadApi, allSent, deleteSentApi, singleRequests, permanentlyDeleteRequest, permanentlyDeleteSent } from './../../api/auth';
import SmallButton from '../../styleComponent/SmallButton/SmallButton';
import { downloadFilesAsZip } from '../../helpers/fileDownload';
import SingleProfileModal from './SingleProfileModal';
import { CreateRequestInterface, MyContactInterface, SendFileListInterface, TemplateInterface } from '../CommonInterface/CommonInterface';
import DashboardLink from './DashboardLink';
import Pagination from './Pagination';
import SentTable from './SentTable';
import { getCookieValue, handleForbiddenError, removeCookie, setCookie, shortEmail } from '../CommonInterface/CommonFunction';
import { myContacts } from '../../api/contact';
import Loader from '../CommonCompoment/Loader/Loader';
import { showNotifications } from '../../helpers/toaster';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import WarningModal from '../CommonCompoment/WarningModal/WarningModal';
import LoadTemplate from './LoadTemplate';
import RequestTable from './RequestTable';
import SmallLoader from '../CommonCompoment/Loader/SmallLoader';

const Dashboard = () => {

    const navigate = useNavigate();
    const [allRequestList, setAllRequestList] = useState<CreateRequestInterface["body"]["request"]>([]);
    const [allTableRequestList, setAllTableRequestList] = useState<CreateRequestInterface["body"]["request"]>([]);
    const [allMyContact, setAllMyContact] = useState<MyContactInterface["body"]["contacts"]>([]);
    const [contactView, setContactView] = useState<number | null>(null);
    const [tableFileView, setTableFileView] = useState<number | null>(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [deleteDownload, setDeleteDownload] = useState(false)
    const [deleteConfirm, setDeleteConfirm] = useState(false)
    const [deleteTempId, setDeleteTempId] = useState("")
    const [contactTag, setContactTag] = useState("ALL");
    const [recipients, setRecipients] = useState<any[]>([]);
    // pagination state
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(1);
    const [limit, setLimit] = useState<number>(6);
    const [filterTag, setFilterTag] = useState("");
    const [deleteTag, setDeleteTag] = useState("");
    const [multiDownload, setMultiDownload] = useState(false);
    const [pageValue, setPageValue] = useState<number>();
    // pagination
    const [deleteId, setDeleteId] = useState(false);
    const [result, setResult] = useState<string[]>([]);
    const [totalValue, setTotalValue] = useState<any>();
    const [limitValue, setLimitValue] = useState<any>();
    const pageCount = Math.ceil(totalValue / limitValue);
    const [prevButton, setPrevButton] = useState<boolean>(false);
    const [nextButton, setNextButton] = useState<boolean>(false);
    const [checkBoxDisable, setCheckBoxDisable] = useState(false);
    const [informationShow, setInformationShow] = useState(false);
    const handleInformationClose = () => setInformationShow(false);
    const [profileId, setProfileId] = useState("");
    const [saveTemplateShow, setSaveTemplateShow] = useState(false);
    const [loadTemplateInfo, setLoadTemplateInfo] = useState<any>([]);
    const [tableId, setTableId] = useState<string[]>([]);
    const [allCheck, setAllCheck] = useState(false);
    const [downloadTooltip, setDownloadTooltip] = useState(false);
    const [downloadMsg, setDownloadMsg] = useState("");
    const [spinLoading, setSpinLoading] = useState<any>(false);
    const [fadeAnimation, setFadeAnimation] = useState(false);
    const [checkedItems, setCheckedItems] = useState<any>([]);
    // sent file
    const [allSentList, setAllSentList] = useState<SendFileListInterface["body"]["sendFileRequest"]>([]);
    const [deleteCount, setDeleteCount] = useState<number>(0);
    const [tableActive, setTableActive] = useState("request");
    const [permanentDelete, setPermanentDelete] = useState<boolean>(false);
    const [warningShow, setWarningShow] = useState(false);
    const handleWarningClose = () => {
        setWarningShow(false);
    }
    const [deleteWarningShow, setDeleteWarning] = useState(false);
    const handleDeleteClose = () => {
        setDeleteWarning(false);
    }
    const headingText = "Selected files will be removed from this list.";
    const paragraphText = '**You will find this file inside the "Delete Files" section.';

    const headingTextWarning = "This will permanently delete all of your file.";
    const paragraphTextWarning = '**Performing this action will permanently delete the selected records';


    const handleWarningShow = () => {
        setWarningShow(true);
        setPermanentDelete(true);
    }


    useEffect(() => {
        setFadeAnimation(true);
        removeCookie('contactId');
    }, []);

    const loadAllRequest = () => {
        allRequests(limit, page, filterTag, checkedItems).then((data: CreateRequestInterface) => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
                else {
                    setAllRequestList([]);
                }
            }
            else {
                if (tableActive === 'request') {
                    setTotalValue(data.body.total)
                    setTableFileView(data.body.total)
                    setLimitValue(data.body.limit)
                    setPageValue(data.body.page)
                }
                const responseTable = data.body.request;
                responseTable.forEach(r => r.isChecked === false)
                setAllRequestList(responseTable)
                setAllTableRequestList(data.body.request)
            }
        })
        // my contact list
        myContacts(100, 1, contactTag, "").then((data) => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
                else {
                    showNotifications("error", data.message);
                }
            }
            else {
                setAllMyContact(data.body.contacts);
                setContactView(data.body.total);
            }
        })

        allSent(limit, page, filterTag, checkedItems).then((data: SendFileListInterface) => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
                else {
                    setAllSentList([])
                }
            }
            else {
                if (tableActive !== 'request') {
                    setTotalValue(data.body.total)
                    setTableFileView(data.body.total)
                    setLimitValue(data.body.limit)
                    setPageValue(data.body.page)
                }
                const responseTable: any = data.body.sendFileRequest;
                responseTable.forEach((r: any) => r.isChecked === false)
                setAllSentList(responseTable);
            }
        })
    }

    useEffect(() => {
        loadAllRequest();
        // prev button
        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }
    }, [deleteCount, page, count, totalValue, limitValue, contactTag, filterTag, checkedItems, loadTemplateInfo, limit, tableActive]);

    // item download
    const itemDownload = async (attachmentObj: any) => {
        setSpinLoading(true)
        const urls: string[] = []
        const itemIds = attachmentObj.items.map((item: any) => item.files)
        for (const item of attachmentObj.items) {
            const fileIds = item.files.map((file: any) => file.fileId)
            for (const [index, fileId] of fileIds.entries()) {
                try {
                    const fileDownloadAs = `${item.itemName}_${index}`;
                    const response = await filesDownloadApi(fileId, fileDownloadAs);
                    if (!response.body || !response.body.downloadUrl) {
                        throw new Error("Invalid response from server");
                    }
                    urls.push(response.body.downloadUrl);
                } catch (error) {
                    removeCookie('docoteam');
                    return navigate("/login");
                }
            }
        }

        if (urls.length === 0) {
            setDownloadTooltip(true)
            setSpinLoading(false)
            showNotifications("error", "File is empty");
        }
        else {
            downloadFilesAsZip(urls, attachmentObj.requestId, setSpinLoading)
        }
    }


    // all download
    const itemDownloadAll = async () => {
        if (tableActive !== 'request') {
            // sent file download
            for (let i = 0; i < sentFileCheckList.length; i++) {
                setSpinLoading(true)
                const itemIds: any = sentFileCheckList[i];

                const urls: string[] = []
                for (let i = 0; i < itemIds.files.length; i++) {
                    const response = await filesDownloadApi(itemIds.files[i].fileId);
                    if (!response.body || !response.body.downloadUrl) {
                        throw new Error("Invalid response from server");
                    }
                    urls.push(response.body.downloadUrl);
                }
                if (urls.length === 0) {
                    setSpinLoading(false)
                    setDownloadTooltip(true)
                    setDownloadMsg("file is empty")
                }
                else {
                    downloadFilesAsZip(urls, sentFileCheckList[i].sendId, setSpinLoading)
                }

            }
        }
        else {
            // request file download
            for (let i = 0; i < objectsWithRequestedIds.length; i++) {
                setSpinLoading(true)
                const urls: string[] = []
                const itemIds: any = objectsWithRequestedIds[i].items;
                for (const item of itemIds) {
                    const fileIds = item.files.map((file: any) => file.fileId);
                    for (const [index, fileId] of fileIds.entries()) {
                        try {
                            const fileDownloadAs = `${item.itemName}_${index}`;
                            const response = await filesDownloadApi(fileId, fileDownloadAs);
                            if (!response.body || !response.body.downloadUrl) {
                                throw new Error("Invalid response from server");
                            }
                            urls.push(response.body.downloadUrl);
                        } catch (error) {
                            removeCookie('docoteam');
                            return navigate("/login");
                        }
                    }
                }
                if (urls.length === 0) {
                    setSpinLoading(false)
                    setDownloadTooltip(true)
                    setDownloadMsg("file is empty")
                }
                else {
                    downloadFilesAsZip(urls, objectsWithRequestedIds[i].requestId, setSpinLoading)
                }
            }
        }
    }

    //    contact searching
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const searchResults = allMyContact.filter((item) => {
        const fullName = `${item.firstName} ${item.lastName} ${item.email}`;
        return fullName.toLowerCase().includes(searchTerm.toLowerCase())
    });

    // table searching
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchQuery = event.target.value.toLowerCase();
        setSearchQuery(searchQuery);
        if (tableActive === 'request') {
            // create request
            const filteredObjects = allTableRequestList.filter((obj) => {
                const objStr = JSON.stringify(obj).toLowerCase();
                const createdAt = moment(obj.createdAt).format('MMM DD').toLowerCase();
                const updatedAt = moment(obj.updatedAt).format('MMM DD').toLowerCase();
                const dueDate = moment(obj.settings.dueDate).format('MMM DD').toLowerCase();
                return objStr.includes(searchQuery) || createdAt.includes(searchQuery) || updatedAt.includes(searchQuery) || dueDate.includes(searchQuery);
            });
            setAllRequestList(filteredObjects);
        }
        else {
            // send
            const filteredObjects = allSentList.filter((obj) => {
                const objStr = JSON.stringify(obj).toLowerCase();
                const createdAt = moment(obj.createdAt).format('MMM DD').toLowerCase();
                const updatedAt = moment(obj.updatedAt).format('MMM DD').toLowerCase();
                const dueDate = moment(obj.settings.dueDate).format('MMM DD').toLowerCase();
                return objStr.includes(searchQuery) || createdAt.includes(searchQuery) || updatedAt.includes(searchQuery) || dueDate.includes(searchQuery);
            });
            setAllSentList(filteredObjects);
        }
    }

    // delete request table data
    const deleteRequestTableFunction = (result: string[]) => {
        for (let i = 0; i < result.length; i++) {
            deleteRequestsApi(result[i]).then((data) => {
                if (data.statusCode !== 200) {
                    if (data.statusCode === 403) {
                        handleForbiddenError(navigate);
                    }
                }
                else {
                    setCheckBoxDisable(false)
                    loadAllRequest()
                    setTableId([])
                    setSpinLoading(false)
                }
            })
        }
    }

    // delete sent table data
    const deleteSentTableFunction = (result: string[]) => {
        for (let i = 0; i < result.length; i++) {
            deleteSentApi(result[i]).then((data) => {
                if (data.statusCode !== 200) {
                    if (data.statusCode === 403) {
                        handleForbiddenError(navigate);
                    }
                }
                else {
                    setCheckBoxDisable(false)
                    loadAllRequest()
                    setTableId([])
                    setSpinLoading(false)
                }
            })
        }
    }

    // delete request
    const deleteRequest = (result: string[]) => {
        if (multiDownload === true) {
            setAllCheck(false)
            setSpinLoading(true)
            if (tableActive === 'request') {
                deleteRequestTableFunction(result)
            }
            else {
                deleteSentTableFunction(result)
            }
        }
        else {
            setSpinLoading(true)
            if (tableActive === 'request') {
                deleteRequestTableFunction(result)
            }
            else {
                deleteSentTableFunction(result)
            }
        }
    }

    // delete item
    function filterOddAndUnique(arr: string[]) {
        const count: any = {};
        const result: string[] = [];

        for (let elem of arr) {
            if (count[elem]) {
                count[elem]++;
            } else {
                count[elem] = 1;
            }
        }
        for (let elem in count) {
            if (count[elem] % 2 !== 0) {
                result.push(elem);
            }
        }
        return result;
    }

    useEffect(() => {
        const result = filterOddAndUnique(tableId);
        setResult(result);
    }, [tableId])

    // all download item
    function findObjectsByRequestedIds(requestedIds: string[]) {
        return allRequestList.filter((obj) => requestedIds.includes(obj.requestId));
    }

    function sentFileDownList(requestedIds: string[]) {
        return allSentList.filter((obj) => requestedIds.includes(obj.sendId));
    }

    const objectsWithRequestedIds = findObjectsByRequestedIds(result);
    const sentFileCheckList = sentFileDownList(result)
    function countCheckedItems(arr: any) {
        let count = 0;
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].isChecked === true) {
                count++;
            }
        }
        return count;
    }
    // single checkbox logic
    const singleTable = (id: string) => {
        setDeleteDownload(!deleteDownload)
        setTableId([...tableId, id])
        setDeleteId(true)
        let tempObj = allRequestList;
        let sentTempObj = allSentList;
        for (let i = 0; i < tempObj.length; i++) {
            // create file
            if (tempObj[i].requestId === id) {
                tempObj[i].isChecked = !tempObj[i].isChecked
                break;
            }
        }
        // send file
        for (let i = 0; i < sentTempObj.length; i++) {
            if (sentTempObj[i].sendId === id) {
                sentTempObj[i].isChecked = !sentTempObj[i].isChecked
                break;
            }
        }
        if (tableActive === 'request') {
            if (countCheckedItems(allRequestList) === allRequestList.length) {
                setAllCheck(true)
            }
            else {
                setAllCheck(false)
            }
        }
        else {
            if (countCheckedItems(allSentList) === allSentList.length) {
                setAllCheck(true)
            }
            else {
                setAllCheck(false)
            }
        }
        setAllRequestList(tempObj)
        setAllSentList(sentTempObj)
    }

    // check all item
    const checkAllSelect = () => {
        setDeleteId(true);
        setMultiDownload(true);
        // request table
        let tempObj = allRequestList;
        let tempArr: any = [];
        if (countCheckedItems(allRequestList) === allRequestList.length) {
            for (let i = 0; i < tempObj.length; i++) {
                tempObj[i].isChecked = false;
                tempArr.push(tempObj[i].requestId)
            }
        }
        else {
            for (let i = 0; i < tempObj.length; i++) {
                tempObj[i].isChecked = true;
                tempArr.push(tempObj[i].requestId)
            }
        }
        // sent table
        let sentTempObj = allSentList;
        let sentArr: any = [];
        if (countCheckedItems(allSentList) === allSentList.length) {
            for (let i = 0; i < sentTempObj.length; i++) {
                sentTempObj[i].isChecked = false;
                sentArr.push(sentTempObj[i].sendId)
            }
        }
        else {
            for (let i = 0; i < sentTempObj.length; i++) {
                sentTempObj[i].isChecked = true;
                sentArr.push(sentTempObj[i].sendId)
            }
        }

        if (tableActive === 'request') {
            if (countCheckedItems(allRequestList) === allRequestList.length) {
                setAllCheck(true)
            }
            else {
                setAllCheck(false)
            }
        }
        else {
            if (countCheckedItems(allSentList) === allSentList.length) {
                setAllCheck(true)
            }
            else {
                setAllCheck(false)
            }
        }

        if (allCheck === false) {
            if (tableActive === 'request') {
                setTableId(tempArr)
            }
            else {
                setTableId(sentArr)
            }
        }
        else {
            setTableId([])
        }
    }

    // file size 
    function sumAttachmentSizeInMB(arr: any[]) {
        const totalBytes = arr.reduce((acc, curr) => {
            for (let i = 0; i < curr.files.length; i++) {
                if (curr.files[i] && curr.files[i].size) {
                    return acc + curr.files[i].size;
                }
            }
            return acc;
        }, 0);
        const totalMB = totalBytes / (1024 * 1024);
        if (totalMB < 0.50) {
            return (totalMB * 1024).toFixed(2) + " KB";
        }
        else {
            return totalMB.toFixed(2) + " MB";
        }
    }

    // pagination 
    function multiplyBySixAndShowSeries(value: number) {
        const numbers: number[] = [];
        for (let i = 1; i <= value; i++) {
            if (i % 6 === 0) {
                numbers.push(i);
            }
        }
        return numbers.reverse();
    }

    // Example usage:
    const pageResult = multiplyBySixAndShowSeries(totalValue);

    const nextPage = () => {
        setAllCheck(false)
        setResult([])
        setTableId([])
        setPage(page + 1)
        setNextButton(true)
    }

    const prevPage = () => {
        setAllCheck(false)
        setResult([])
        setTableId([])
        setPage(page - 1)
    }

    // single item information
    const InformationApi = (requestId: any) => {
        setProfileId(requestId)
        setInformationShow(true)
    }

    // load template
    const loadTemplateList = () => {
        loadTemplate().then((data: TemplateInterface) => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
                else {
                    setLoadTemplateInfo([])
                }
            }
            else {
                setSpinLoading(false)
                setLoadTemplateInfo(data.body.request)
            }
        })
    }

    // template
    const saveTemplateModal = () => {
        setSpinLoading(true)
        setSaveTemplateShow(true)
        loadTemplateList()
    };

    const saveTemplateModalClose = () => {
        setSaveTemplateShow(false)
    };



    // Load recipients from localStorage on component mount
    useEffect(() => {
        localStorage.removeItem("recipients");
        setRecipients([]);
    }, []);

    const handleCheckboxChange = (contact: any) => {
        setRecipients((prevRecipients) => {
            const isAlreadyAdded = prevRecipients.some((item) => item.contactId === contact.contactId);
            let updatedRecipients;
            if (isAlreadyAdded) {
                updatedRecipients = prevRecipients.filter((item) => item.contactId !== contact.contactId);
            } else {
                const newRecipient = {
                    contactId: contact.contactId,
                    fullName: `${contact.firstName} ${contact.lastName}`,
                    email: contact.email,
                    phoneNumber: contact.phoneNumber && contact.phoneNumber.length ? contact.phoneNumber : ""
                };
                updatedRecipients = [...prevRecipients, newRecipient];
            }
            localStorage.setItem("recipients", JSON.stringify(updatedRecipients));
            return updatedRecipients;
        });
    };

    // template delete
    const confirmDelete = (templateId: string) => {
        setDeleteTempId(templateId)
        setDeleteConfirm(true)
        setSaveTemplateShow(false)
    }

    const deleteTemplateConfirm = () => {
        setDeleteConfirm(false)
    }

    // delete template
    const deleteTemplate = () => {
        setSpinLoading(true)
        deleteTemplateApi(deleteTempId).then((data: TemplateInterface) => {
            loadTemplateList();
            setDeleteConfirm(false)
            setTimeout(() => {
                setSpinLoading(false)
            }, 1000)
        })
    }


    // edit request file
    const editRequest = (requestId: string) => {
        singleRequests(requestId).then((data) => {
            return navigate(`/create-request/${requestId}`)
        })
    };

    // delete request file
    const requestFileApi = () => {
        deleteRequest(result);
        setWarningShow(false);
    }

    const selectedFilter = (tag: string) => {
        setFilterTag(tag);
        setResult([]);
        setAllCheck(false);
        if (tag === "delete") {
            setDeleteTag("delete");
        }
        else {
            setDeleteTag("");
        }
    }

    const deletePermanentModal = () => {
        setDeleteWarning(true);
    }

    // permanent send delete
    const deleteSentPermanent = () => {
        for (let i = 0; i < sentFileCheckList.length; i++) {
            permanentlyDeleteSent(sentFileCheckList[i].sendId).then((data) => {
                if (data.statusCode !== 200) {
                    if (data.statusCode === 403) {
                        handleForbiddenError(navigate);
                    }
                    showNotifications('error', data.message)
                }
                else {
                    setDeleteWarning(false);
                    setDeleteCount(deleteCount + 1);
                }
            })
        }
        showNotifications('success', "Successfully delete your files")
        setAllCheck(false);
        setDeleteTag("");
        setResult([]);
    };
    // permanent request delete
    const deleteRequestPermanent = () => {
        for (let i = 0; i < objectsWithRequestedIds.length; i++) {
            permanentlyDeleteRequest(objectsWithRequestedIds[i].requestId).then((data) => {
                if (data.statusCode !== 200) {
                    if (data.statusCode === 403) {
                        handleForbiddenError(navigate);
                    }
                    showNotifications('error', data.message)
                }
                else {
                    setDeleteWarning(false);
                    setDeleteCount(deleteCount + 1);
                }
            })
        }
        showNotifications('success', "Successfully delete your files")
        setAllCheck(false);
        setDeleteTag("");
        setResult([]);
    }

    const dashboardLoad = () => {
        setCount(count + 1)
    }

    return (
        <>
            <DashboardPage className={`fade-in ${fadeAnimation ? 'visible' : ''}`}>
                {spinLoading && <Loader />}
                <ToastContainer />
                <DashboardNavbar dashboardLoad={dashboardLoad} />
                <section className="tableData">
                    <DashboardLink setPage={setPage} setLimit={setLimit} setAllCheck={setAllCheck} allSentList={allSentList} allRequestList={allRequestList} setResult={setResult} setTableId={setTableId} setTableActive={setTableActive} tableActive={tableActive} saveTemplateModal={saveTemplateModal} />
                    <Container fluid>
                        <Row>
                            <Col lg={3}>
                                <div className="contactList">
                                    <h1>My Contacts</h1>
                                    {contactView === null ? <SmallLoader /> : <><div className="searchName dashboardInput">
                                        <input type="text" value={searchTerm} onChange={handleInputChange} className="form-control" id="inputValidation" placeholder="Search Contacts" />
                                        <FontAwesomeIcon icon={faSearch} />
                                    </div>
                                        <div className="contactFilter">
                                            <button onClick={() => setContactTag("ALL")} className={contactTag === "ALL" ? "active" : ""}>All</button>
                                            <button onClick={() => setContactTag("CONTACT")} className={contactTag === "CONTACT" ? "active" : ""}>Contacts</button>
                                            <button onClick={() => setContactTag("GROUP")} className={contactTag === "GROUP" ? "active" : ""}>Groups</button>
                                        </div>
                                        <div className="contact">
                                            {searchResults.length ? "" : <p className='fileEmpty mt-3'>No Contact</p>}
                                            {searchResults.map((item: any, index: number) =>
                                                <div className='myContactList' key={`contactSearch` + index}>
                                                    <label className='checkLine'>
                                                        <div className='contactCheck'>
                                                            <input type="checkbox" value={item.email} onChange={() => handleCheckboxChange(item)} checked={recipients.some((c) => c.contactId === item.contactId)} />
                                                            <span className="checkmark"></span>
                                                        </div>
                                                    </label>
                                                    <div className="contactNameList">
                                                        <Link to={`/my-contact/${item.contactId}`} className='contactName'>{item.firstName}&nbsp;{item.lastName}</Link>
                                                        <Link to={`/my-contact/${item.contactId}`} className={item.email && item.email.length > 15 ? 'tooltip-top contactEmail' : "contactEmail"} data-tooltip={item.email && item.email.length > 15 ? item.email : ""}>{shortEmail(item.email)}</Link>
                                                        <span className='groupName'>{item.group?.groupName}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div></>}
                                </div>
                            </Col>
                            <Col lg={9}>
                                <div className="rightSideTable">
                                    <div className="tableTopContent">
                                        <div className="topBoxHeading">
                                            <h1>My Requests</h1>
                                        </div>
                                        <div className="topButtonContent">
                                            <div className="searchName tableSearch dashboardInput">
                                                <input type="text" value={searchQuery} onChange={handleSearch} className="form-control" id="inputValidation" placeholder="Search" />
                                                <FontAwesomeIcon icon={faSearch} />
                                            </div>
                                            <div className="filterBtn">
                                                <button className={filterTag === "" ? 'btn activeFilter' : 'btn'} onClick={() => selectedFilter("")}>All</button>
                                                <button className={filterTag === "group" ? 'btn activeFilter' : 'btn'} onClick={() => selectedFilter("group")}>Group</button>
                                                {tableActive === 'request' ? <>
                                                    <button className={filterTag === "inprogress" ? 'btn activeFilter' : 'btn'} onClick={() => selectedFilter("inprogress")}>In Progress</button>
                                                    <button className={filterTag === "completed" ? 'btn activeFilter' : 'btn'} onClick={() => selectedFilter("completed")}>Complete</button>
                                                </> : <>
                                                    <button className={filterTag === "individual" ? 'btn activeFilter' : 'btn'} onClick={() => selectedFilter("individual")}>Individual</button>
                                                </>}
                                                <button className={filterTag === "expired" ? 'btn activeFilter' : 'btn'} onClick={() => selectedFilter("expired")}>Expired</button>
                                                <button className={filterTag === "delete" ? 'btn activeFilter' : 'btn'} onClick={() => selectedFilter("delete")}>Delete</button>
                                            </div>
                                        </div>
                                    </div>

                                    {result && result.length > 0 ? <div className="chooseItem">
                                        {deleteTag === "delete" ? <><button className='removeBtn' onClick={() => deletePermanentModal()}><SmallButton customWidth="135" customHeight="24" customBackgroundColor='#fff' customBorderColor='#fff' customTextColor='#747679'>Delete selected items</SmallButton></button></> : <>
                                            <button className='removeBtn mr-3' onClick={() => itemDownloadAll()}><SmallButton customWidth="82" customHeight="24" customBackgroundColor='#fff' customBorderColor='#fff' customTextColor='#747679;' >Download</SmallButton></button>
                                            <button className='removeBtn' onClick={() => handleWarningShow()}><SmallButton customWidth="72" customHeight="24" customBackgroundColor='#fff' customBorderColor='#fff' customTextColor='#747679'>Remove</SmallButton></button>
                                        </>}
                                    </div> : <></>}


                                    {tableActive === 'request' ?
                                        <>
                                            {tableFileView === null ? <SmallLoader /> : <RequestTable filterTag={filterTag} checkAllSelect={checkAllSelect} allCheck={allCheck} setAllRequestList={setAllRequestList} deleteCount={deleteCount} setDeleteCount={setDeleteCount} allRequestList={allRequestList} itemDownload={itemDownload} InformationApi={InformationApi} singleTable={singleTable} editRequest={editRequest} />}

                                        </> :
                                        <>
                                            {tableFileView === null ? <SmallLoader /> : <SentTable filterTag={filterTag} checkAllSelect={checkAllSelect} setAllSentList={setAllSentList} deleteCount={deleteCount} setDeleteCount={setDeleteCount} setSpinLoading={setSpinLoading} allCheck={allCheck} singleTable={singleTable} allSentList={allSentList} />}
                                        </>
                                    }

                                    {tableActive === 'request' ?
                                        <>
                                            {allRequestList && allRequestList.length > 0 ? <><Pagination tableActive={tableActive} allSentList={allSentList} limit={limit} setLimit={setLimit} pageResult={pageResult} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} allRequestList={allRequestList} /></> : <></>}
                                        </> : <>
                                            {allSentList && allSentList.length > 0 ? <><Pagination tableActive={tableActive} allSentList={allSentList} limit={limit} setLimit={setLimit} pageResult={pageResult} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} allRequestList={allRequestList} /></> : <></>}
                                        </>
                                    }
                                </div>
                            </Col>
                        </Row>

                        {/* table single profile show */}
                        <SingleProfileModal itemDownload={itemDownload} handleInformationClose={handleInformationClose} informationShow={informationShow} sumAttachmentSizeInMB={sumAttachmentSizeInMB} profileId={profileId} setInformationShow={setInformationShow} />

                        {/* load template */}
                        <LoadTemplate saveTemplateShow={saveTemplateShow} loadTemplateInfo={loadTemplateInfo} confirmDelete={confirmDelete} saveTemplateModalClose={saveTemplateModalClose} />
                        {/* delete template */}

                        <Modal show={deleteConfirm} className="loadModal settingModalBody" size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>
                            <Modal.Body>
                                <div className="templateModal">
                                    <Row>
                                        <Col md={12} className="requestInputForm ">
                                            <div className="deleteTemplateName">
                                                <div className="loadTemplateHeading">
                                                    <h1>Are you sure want to delete ?</h1>
                                                </div>
                                                <div className="deleteBtn">
                                                    <button className='noDelete' onClick={deleteTemplateConfirm}>NO</button>
                                                    <button className='yesDelete' onClick={deleteTemplate}>YES</button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Modal.Body>
                        </Modal>

                    </Container>
                </section>

                <WarningModal headingText={headingText} paragraphText={paragraphText} deleteEmployee={requestFileApi} handleWarningClose={handleWarningClose} warningShow={warningShow} />

                {tableActive === "request" ?
                    <WarningModal headingText={headingTextWarning} paragraphText={paragraphTextWarning} deleteEmployee={deleteRequestPermanent} handleWarningClose={handleDeleteClose} warningShow={deleteWarningShow} />
                    : <WarningModal headingText={headingTextWarning} paragraphText={paragraphTextWarning} deleteEmployee={deleteSentPermanent} handleWarningClose={handleDeleteClose} warningShow={deleteWarningShow} />
                }


            </DashboardPage >
        </>
    )
}

export default Dashboard
