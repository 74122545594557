import React, { useState, useRef, useEffect } from 'react';
import Settings from '../SentFiles/Settings';
import TwoFA from '../Sent/TwoFA';
import ViewRequest from '../SentFiles/ViewRequest';
import { Col, Container, Row, Dropdown } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import ContactList from '../MyContact/ContactList';
import CreateContact from '../MyContact/CreateContact';
import Recipient from '../MyContact/Recipient';
import { myContacts } from '../../api/contact';
import { useNavigate, useParams } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { completeUploadApi, requests, singleRequests, updateRequest } from '../../api/auth';
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import { Oval } from 'react-loader-spinner';
import { AttachmentUPloadInterface, ItemInterface, RecipientUser, SettingsInterface } from '../CommonInterface/CommonInterface';
import { handleAttachmentUpload } from '../../helpers/fileUpload';
import Loader from '../CommonCompoment/Loader/Loader';
import { showNotifications } from '../../helpers/toaster';
import { getCookieValue, handleForbiddenError, isValidDate, removeCookie } from '../CommonInterface/CommonFunction';
import "../SentFiles/SentFiles.css"
import DashboardNavbar from '../CommonCompoment/DashboardNavbar/DashboardNavbar';
import requestState from '../../helpers/requestState';
import ProgressLine from '../CommonCompoment/ProgressBar/ProgressLine';

const RequestContact = () => {

    const navigate = useNavigate();
    const [loginFail, setLoginFail] = useState(false)
    const [loginSuccess, setLoginSuccess] = useState(false)
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
    } = useForm();
    const [progressLoading, setProgressLoading] = useState<boolean>(false);
    const [requestTitleValue, setRequestTitleValue] = useState<string>("");
    const [show, setShow] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [senderTypeValue, setSenderTypeValue] = useState("individual");
    const [errorContact, setErrorContact] = useState("")
    const [senderError, setSenderError] = useState("")
    const [reminderError, setReminderError] = useState("")
    const [activeSender, setActiveSender] = useState(false)
    const [receiptShow, setReceiptShow] = useState(false);
    const [customEmailValue, setCustomEmailValue] = useState<string>("");
    const [dateError, setDateError] = useState('');
    const [startDate, setStartDate] = useState<any>("");
    const [dueDateError, setDueDateError] = useState("")
    const [reminderValueName, setReminderValueName] = useState("None")
    const [authValue, setAuthValue] = useState(false)
    const [reminderValue, setReminderValue] = useState(0);
    const [recipientsValue, setRecipientsValue] = useState<RecipientUser[]>([]);
    const [itemValue, setItemValue] = useState<ItemInterface[]>([]);
    const [userIds, setUserIds] = useState<string[]>([]);
    const [settingsValue, setSettingsValue] = useState<SettingsInterface>();
    const [attachmentUploadList, setAttachmentUploadList] = useState<AttachmentUPloadInterface[]>([])
    const [searchTerm, setSearchTerm] = useState("");
    const [dueDate, setDueDate] = useState<any>(startDate);
    const [fadeAnimation, setFadeAnimation] = useState(false);
    const [spinLoading, setSpinLoading] = useState(false);
    const [spinNumberLoading, setSpinNumberLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [authShow, setAuthShow] = useState(false);
    const [downloadTooltip, setDownloadTooltip] = useState(false);
    const handleClose = () => setShow(false);

    const requestObj = JSON.parse(localStorage.getItem("request") || '{}');

    const AuthModalClose = () => {
        if (authValue === true) {
            setShow(true)
            setAuthShow(false)
        }
        else {
            setAuthShow(false)
        }
    }

    function getReminderName(reminderTime: any) {
        const reminderChoose = [
            { reminderName: "None", reminderTime: 0 },
            { reminderName: "1 Hour", reminderTime: 3600000 },
            { reminderName: "6 Hours", reminderTime: 21600000 },
            { reminderName: "12 Hours", reminderTime: 43200000 },
            { reminderName: "24 Hours", reminderTime: 86400000 },
        ];
        const reminderOption = reminderChoose.find(option => option.reminderTime === reminderTime);
        return reminderOption ? reminderOption.reminderName : 'Unknown';
    }


    useEffect(() => {
        const { files } = requestState;
        const requestFileObj = files;
        setFadeAnimation(true);
        if (id) {
            singleRequests(id).then((data) => {
                // setRequestTitleValue(data.body.title)
                setRecipientsValue(data.body.recipients && data.body.recipients.users);
                setAuthValue(data.body.settings.isMfaRequired);
                setReminderValue(data.body.settings.reminders);
                const selectedDate = new Date(data.body.settings.dueDate);
                selectedDate.setHours(0, 0, 0, 0);
                if (data.body.settings.dueDate) {
                    setDueDate(selectedDate);
                    setStartDate(selectedDate);
                }
                setCustomEmailValue(data.body.settings.customEmailMsg);
                setItemValue(requestObj.items)
            })
        }
        else {
            if (requestObj.recipients) {
                setRecipientsValue(requestObj.recipients.users);
            }
            if (requestObj.settings) {
                setCustomEmailValue(requestObj.settings.customEmailMsg);
                const selectedDate = new Date(requestObj.settings.dueDate);
                selectedDate.setHours(0, 0, 0, 0);
                if (requestObj.settings.dueDate) {
                    setStartDate(selectedDate)
                }
                const reminderName = getReminderName(requestObj.settings.reminders);
                setReminderValueName(reminderName)
            }
            setItemValue(requestObj.items)
            if (requestFileObj) {
                setAttachmentUploadList(requestFileObj)
            }
            if (requestObj.associatedUserIds) {
                setUserIds(requestObj.associatedUserIds)
            }
            const recipientsObj = JSON.parse(localStorage.getItem("recipients") || '[]');
            if (recipientsObj.length > 0) {
                setRecipientsValue(recipientsObj);
                const existingDataString = JSON.parse(localStorage.getItem("request") || '{}');
                const updatedData = { ...existingDataString, recipients: { ...existingDataString.recipients, users: recipientsObj } };
                const jsonString = JSON.stringify(updatedData);
                localStorage.setItem('request', jsonString);
                localStorage.removeItem("recipients");
            }
        }
        setRequestTitleValue(requestObj.title)
    }, []);

    const reminderChoose = [
        { reminderName: "None", reminderTime: 0 },
        { reminderName: "1 Day", reminderTime: 86400000 },
        { reminderName: "3 Days", reminderTime: 259200000 },
        { reminderName: "5 Days", reminderTime: 432000000 },
        { reminderName: "7 Days", reminderTime: 604800000 },
    ];

    const handleShow = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        if (recipientsValue.length === 0) {
            setErrorContact("Please select a contact here")
        }
        else {
            setErrorContact("")
        }
        if (senderTypeValue.length === 0) {
            setSenderError("Please select sender type")
        }
        else {
            setSenderError("")
        }
        if (recipientsValue.length > 0 && senderTypeValue.length > 0) {
            setShow(true)
            setErrorContact("")
            setSenderError("")

            let recipientsObj = {
                "users": recipientsValue,
                "senderType": senderTypeValue
            }
        }
    };

    const successHandleClose = () => {
        if (authValue === true) {
            setReceiptShow(false)
            setAuthShow(true)
        }
        else {
            setReceiptShow(false)
            setShow(true)
        }
    };

    const senderTypeFunction = (type: string) => {
        setSenderTypeValue(type)
        setActiveSender(!activeSender)
    }
    const customEmail = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setCustomEmailValue(event.target.value)
    }

    const dueDateChange = (date: any) => {
        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0);
        setDueDate(selectedDate);
        setStartDate(selectedDate)
    }



    useEffect(() => {
        setFadeAnimation(true);
    }, [startDate])

    //get my contacts 
    let getMyContacts = () => {
        myContacts(100, 1, "", "").then(data => {
            if (data.statusCode !== 200) {
                if (data.statusCode === 403) {
                    handleForbiddenError(navigate);
                }
                else {
                    showNotifications("error", data.message);
                }
            }
            else {
                setContacts(data.body.contacts)
                setValue("email", "", { shouldValidate: false });
                setValue("firstName", "", { shouldValidate: false });
                setValue("lastName", "", { shouldValidate: false });
            }
        })
    }

    //load my contacts on load
    useEffect(() => {
        getMyContacts();
        if (id) {
            singleRequests(id).then((data) => {
                setRecipientsValue(data.body.recipients && data.body.recipients.users);
            })
        }
    }, [])

    const authClick = () => {
        setAuthValue(!authValue)
    }
    const successHandleShow = () => {

        const dateFuture = moment(startDate).format('YYYY-MM-DD')
        const dueDateSave = isValidDate(dateFuture);

        if (dueDate) {
            if (dueDateSave === false) {
                setDueDateError("Please insert future date")
            }
            else {
                setDueDateError("")
            }
        }
        if (reminderValueName.length === 0) {
            setReminderError("Please choose reminder time")
        }
        else {
            setReminderError("")
        }
        setDateError("")
        const settingObj = {
            "settingsId": uuidv4(),
            "customEmailMsg": customEmailValue,
            ...(dueDate && { "dueDate": dueDate }),
            "reminders": reminderValue,
            "isMfaRequired": authValue
        }
        setSettingsValue(settingObj);
        const existingDataString = JSON.parse(localStorage.getItem("request") || '{}');
        const updatedData = { ...existingDataString, ...{ settings: settingObj } };
        const jsonString = JSON.stringify(updatedData);
        localStorage.setItem('request', jsonString);


        if (dueDateSave !== false) {
            setShow(false);
            if (authValue === true) {
                setAuthShow(true)
            }
            else {
                setReceiptShow(true)
            }
        }


    };

    function getFileFromLocalStorage(fileDataString: any): File | null {
        if (fileDataString) {
            const fileData = fileDataString;
            const base64String = fileData.base64;
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: fileData.type });
            const file = new File([blob], fileData.name, { type: fileData.type });
            return file;
        } else {
            return null;
        }
    }

    const fullModalClose = () => {
        setSpinNumberLoading(true)
        setProgressLoading(true)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        const requestObject = {
            "title": requestTitleValue,
            "items": itemValue,
            "settings": settingsValue,
            "recipients": {
                "users": recipientsValue,
                "senderType": senderTypeValue
            },
            "associatedUserIds": userIds
        }
        const requestUpdateObject = {
            "title": requestTitleValue,
            "requestId": id,
            "items": itemValue,
            "settings": settingsValue,
            "recipients": {
                "users": recipientsValue,
                "senderType": senderTypeValue
            },
            "associatedUserIds": userIds
        }
        if (id) {
            updateRequest(id, requestUpdateObject).then(async (data: any) => {
                if (data.message === "Failed to Send SMS!") {
                    setSpinNumberLoading(false)
                    setProgressLoading(false)
                    setDownloadTooltip(true)
                }
                else {
                    let progress = 0;
                    const uploadLockIds = data.attachmentUploadLockIds;
                    for (let i = 0; i < attachmentUploadList.length; i++) {
                        const attachmentId = uploadLockIds[attachmentUploadList[i].attachmentUploadLockId];
                        let fileValue = getFileFromLocalStorage(attachmentUploadList[i].file);
                        await handleAttachmentUpload(fileValue, `/item-attachment/uploadUrl/${attachmentId}`, i, (progress: any) => {
                            setItemValue((prevList: any) => {
                                const newList = [...prevList];
                                newList[i] = { ...newList[i], progress };
                                return newList;
                            });
                        });
                        await completeUploadApi(attachmentId)
                        setItemValue((prevList: any) => {
                            const newList = [...prevList];
                            newList[i] = { ...newList[i], status: 'uploaded' };
                            return newList;
                        });
                    }
                    removeCookie('contactId');
                    setSpinNumberLoading(false)
                    setProgressLoading(false)
                    return navigate('/dashboard')
                }
            })
        }
        else {

            requests(requestObject).then(async (data: any) => {
                if (data.message === "Failed to Send SMS!") {
                    setSpinNumberLoading(false)
                    setProgressLoading(false)
                    setDownloadTooltip(true)
                }
                else {
                    let progress = 0;
                    const uploadLockIds = data.body.attachmentUploadLockIds;

                    for (let i = 0; i < attachmentUploadList.length; i++) {
                        const attachmentId = uploadLockIds[attachmentUploadList[i].attachmentUploadLockId];
                        let fileValue = getFileFromLocalStorage(attachmentUploadList[i].file);
                        await handleAttachmentUpload(fileValue, `/item-attachment/uploadUrl/${attachmentId}`, i, (progress: any) => {
                            // setItemValue((prevList: any) => {
                            //     const newList = [...prevList];
                            //     newList[i] = { ...newList[i], progress };
                            //     return newList;
                            // });
                        });
                        await completeUploadApi(attachmentId)
                        // setItemValue((prevList: any) => {
                        //     const newList = [...prevList];
                        //     newList[i] = { ...newList[i], status: 'uploaded' };
                        //     return newList;
                        // });
                    }
                    removeCookie('contactId');
                    setSpinNumberLoading(false)
                    setProgressLoading(false)
                    return navigate('/dashboard')
                }
            })
        }
    }

    // show error

    const backCreateFile = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        // setRequestSent(true)
        return navigate('/create-request');
    }

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            if (show === true) {
                successHandleShow();
            }
            else if (receiptShow === true) {
                fullModalClose();
            }
            else if (recipientsValue.length) {
                handleShow();
            }
            else if (authShow === true) {
                setShow(false);
            }

        }
    };

    useEffect(() => {
        document.body.addEventListener('keypress', handleKeyPress);
        return () => {
            document.body.removeEventListener('keypress', handleKeyPress);
        };
    }, [recipientsValue, id, reminderValue, senderTypeValue, reminderValueName, customEmailValue, startDate, authValue, requestTitleValue, itemValue, settingsValue, userIds]);

    const showError = () => {
        return (
            <>
                <ToastContainer position="top-end" className="p-3">
                    <Toast onClose={() => setLoginFail(false)} show={loginFail} delay={3000} autohide>
                        <div className="bg-danger">
                            <Toast.Body><p className='text-white'>{loginFail}</p></Toast.Body>
                        </div>
                    </Toast>
                </ToastContainer>
            </>
        );
    };

    const showSuccess = () => {
        return (
            <>
                <ToastContainer position="top-end" className="p-3">
                    <Toast onClose={() => setLoginSuccess(false)} show={loginSuccess} delay={3000} autohide>
                        <div className="bg-primary">
                            <Toast.Body>{loginSuccess}</Toast.Body>
                        </div>
                    </Toast>
                </ToastContainer>
            </>
        );
    };

    const chooseReminder = (reminderName: any, reminderTime: any) => {
        setReminderValueName(reminderName);
        setReminderValue(reminderTime);
    }

    const reminderDownMenu = () => {
        return reminderChoose.map((data, i: number) => (
            <div key={`navbar` + i}>
                <div key={`navbar + ${i}`}>
                    <Dropdown.Item key={'navbar' + i} className='dropdownImg' onClick={() => chooseReminder(data.reminderName, data.reminderTime)}>
                        {data.reminderName}
                    </Dropdown.Item>
                </div>
            </div>
        ))
    }

    const showTooltip = () => {
        return (
            <>
                <ToastContainer position="top-end" className="p-3">
                    <Toast onClose={() => setDownloadTooltip(false)} show={downloadTooltip} delay={2000} autohide>
                        <div className="bg-danger text-white">
                            <Toast.Body>Please enter valid phone number</Toast.Body>
                        </div>
                    </Toast>
                </ToastContainer>
            </>
        );
    };

    const fileRemove = (emailToRemove: string) => {
        const updatedRecipients: any = recipientsValue.filter(recipient => recipient.email !== emailToRemove);
        setRecipientsValue(updatedRecipients);
        let localStorageValue = JSON.parse(localStorage.getItem("request") || '{}');
        localStorageValue.recipients.users = updatedRecipients;
        localStorage.setItem('request', JSON.stringify(localStorageValue));
    };




    return (
        <>
            {showError()}
            {showSuccess()}
            {showTooltip()}
            <DashboardNavbar />
            {spinLoading && <Loader />}
            {spinNumberLoading && <Loader />}
            {/* {progressLoading && <ProgressLine attachmentList={itemValue} />} */}
            {/* <DashboardNavbar /> */}
            <section className={`fade-in sentFiles ${fadeAnimation ? 'visible' : ''}`} >
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <h1 className='sentHeading'>Create Request</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={5}>
                            <ContactList contacts={contacts} setErrorContact={setErrorContact} recipientsValue={recipientsValue} senderTypeValue={senderTypeValue} setRecipientsValue={setRecipientsValue} />
                        </Col>
                        <Col lg={4}>
                            <Recipient errorContact={errorContact} senderTypeValue={senderTypeValue} senderTypeFunction={senderTypeFunction} fileRemove={fileRemove} setRecipientsValue={setRecipientsValue} recipientsValue={recipientsValue} />
                        </Col>
                        <Col lg={3}>
                            <CreateContact setSpinLoading={setSpinLoading} getMyContacts={getMyContacts} />
                        </Col>

                        <Col md={12}>
                            <div className="footerDashboard">
                                <div className="templateButton">
                                    <button className='backDashboard' onClick={backCreateFile}>Back</button>

                                    <button className='nextButton' onClick={handleShow}>Next</button>

                                </div>
                            </div>

                            {/* request settings */}
                            <Settings dueDateError={dueDateError} customEmail={customEmail} customEmailValue={customEmailValue} dueDateChange={dueDateChange} authClick={authClick} show={show} dateError={dateError} startDate={startDate} reminderValueName={reminderValueName} reminderError={reminderError} authValue={authValue} handleClose={handleClose} successHandleShow={successHandleShow} reminderDownMenu={reminderDownMenu} />
                            <TwoFA setAuthShow={setAuthShow} setReceiptShow={setReceiptShow} setRecipientsValue={setRecipientsValue} authShow={authShow} AuthModalClose={AuthModalClose} recipientsValue={recipientsValue} />
                            {/* view create request */}
                            <ViewRequest itemValue={itemValue} recipientsValue={recipientsValue} requestTitleValue={requestTitleValue} authValue={authValue} dueDate={dueDate} customEmailValue={customEmailValue} receiptShow={receiptShow} reminderValueName={reminderValueName} successHandleClose={successHandleClose} fullModalClose={fullModalClose} />

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default RequestContact