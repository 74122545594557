import React, { useState } from 'react'
import moment from 'moment';
import { SendFileListInterface } from '../CommonInterface/CommonInterface';
import { filesDownloadApi, permanentlyDeleteSent, updateRequest } from '../../api/auth';
import { getCookieValue, sumFileSize, tooltipFullName, transformFullName } from '../CommonInterface/CommonFunction';
import { downloadFilesAsZip } from '../../helpers/fileDownload';
import { useNavigate } from 'react-router-dom';
import WarningModal from '../CommonCompoment/WarningModal/WarningModal';
import { showNotifications } from '../../helpers/toaster';
// google font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { editSentFile } from '../../api/sent';
import { v4 as uuidv4 } from 'uuid';
import { restoreDelete } from '../../api/files';

interface SentFileProps {
    allSentList: SendFileListInterface["body"]["sendFileRequest"];
    singleTable: (id: string) => void;
    allCheck: boolean;
    setSpinLoading: any;
    deleteCount: number;
    setDeleteCount: (value: number) => void;
    setAllSentList: (value: SendFileListInterface["body"]["sendFileRequest"]) => void;
    checkAllSelect: () => void;
    filterTag?: string;
}


const SentTable = ({ filterTag, checkAllSelect, setAllSentList, deleteCount, setDeleteCount, setSpinLoading, allCheck, singleTable, allSentList }: SentFileProps) => {

    const token = JSON.parse(getCookieValue('docoteam') || '{}');
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();
    const headingText = "This will permanently delete all of your file";
    const paragraphText = "**You won't be able to recover any file";

    const [sentId, setSentId] = useState("");
    const [warningShow, setWarningShow] = useState(false);
    const handleWarningClose = () => {
        setWarningShow(false);
    }
    const handleWarningShow = (sendId: string) => {
        setSentId(sendId);
        setWarningShow(true);
    }
    const [tableName, setTableName] = useState(false);
    const [tableRecipient, setTableRecipient] = useState(false);
    const [tableRequester, setTableRequester] = useState(false);
    const [tableRequestType, setTableRequestType] = useState(false);
    const [tableCreated, setTableCreated] = useState(false);
    const [tableDue, setTableDue] = useState(false);
    const [tableUpdated, setTableUpdated] = useState(false);
    const [tableProgress, setTableProgress] = useState(false);
    const [sortBy, setSortBy] = useState(null);
    // tooltipFullName(users: any)
    const downloadFile = async (fileInfo: any) => {
        setSpinLoading(true)
        const urls: string[] = []
        for (let i = 0; i < fileInfo.files.length; i++) {
            const response = await filesDownloadApi(fileInfo.files[i].fileId);
            if (!response.body || !response.body.downloadUrl) {
                throw new Error("Invalid response from server");
            }
            urls.push(response.body.downloadUrl);
        }
        if (urls.length === 0) {
            setSpinLoading(false)
        }
        else {
            downloadFilesAsZip(urls, fileInfo.sendId, setSpinLoading)
        }
    }

    // tooltipFullName(users: any)
    const editFile = (sendId: string) => {
        return navigate(`/sent/${sendId}`);
    }

    const sentFileApi = () => {
        permanentlyDeleteSent(sentId).then((data) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message)
            }
            else {
                showNotifications('success', "Successfully delete your files")
                setWarningShow(false);
                setDeleteCount(deleteCount + 1);
            }
        })
    }


    // sorting table data
    const sortData = (columnName: any) => {
        if (columnName === "title") {
            setTableName(!tableName)
        }
        if (columnName === "recipient") {
            setTableRecipient(!tableRecipient)
        }
        if (columnName === "requester") {
            setTableRequester(!tableRequester)
        }
        if (columnName === "requestType") {
            setTableRequestType(!tableRequestType)
        }
        if (columnName === "createdAt") {
            setTableCreated(!tableCreated)
        }
        if (columnName === "settings") {
            setTableDue(!tableDue)
        }
        if (columnName === "updatedAt") {
            setTableUpdated(!tableUpdated)
        }
        if (columnName === "progress") {
            setTableProgress(!tableProgress)
        }
        let sorted = [...allSentList];
        if (sortBy === columnName) {
            sorted.reverse();
        } else {
            sorted.sort((a: any, b: any) => {
                if (a[columnName] < b[columnName]) return -1;
                if (a[columnName] > b[columnName]) return 1;
                return 0;
            });
        }
        setAllSentList(sorted);
        setSortBy(columnName);
    };


    const RestoreFile = (sendId: any) => {
        restoreDelete('sendFiles', sendId).then(async (data: any) => {
            if (data.statusCode !== 200) {
                showNotifications('error', data.message)
            }
            else {
                showNotifications('success', "Successfully restore your files")
                setDeleteCount(deleteCount + 1);
            }
        });
    };

    return (
        <>
            <ToastContainer />
            <Table responsive className='tableBody dashboardTable'>
                <thead>
                    <tr>
                        <th className='tableMark'>
                            <label className='tableCheckBox mobileTable'>
                                <div className="contactCheck">
                                    <input type="checkbox" onClick={checkAllSelect} checked={allCheck} onChange={() => { }} />
                                    <span className="checkmark"></span>
                                </div>
                            </label>
                        </th>
                        <th onClick={() => sortData("title")}>Request Name {tableName ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("recipient")} >Recipient(s) {tableRecipient ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("requester")} >Requester {tableRequester ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("requestType")} >Request type {tableRequestType ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("createdAt")} >Created {tableCreated ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("settings")} >Due  {tableDue ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th onClick={() => sortData("updatedAt")} >Updated {tableUpdated ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronUp} />}</th>
                        <th>Size</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {allSentList && allSentList.length ? <>
                        {allSentList && allSentList.map((data, index: number) => <tr key={`request` + index} className='dashboardItem'>
                            <td>
                                <label className='tableCheckBox'>
                                    <div className="contactCheck">
                                        <input type="checkbox" onClick={() => singleTable(data.sendId)} checked={data.isChecked || allCheck} onChange={() => { }} />
                                        <span className="checkmark"></span>
                                    </div>
                                </label>
                            </td>
                            <td className='infoLink align-items-center'>
                                <p>
                                    {data.debouncedInfo ? <span className='tooltip-top' data-tooltip="Don't get the mail"><FontAwesomeIcon icon={faTriangleExclamation} /></span> : ""}
                                    <span className={data.settings.title && data.settings.title.length > 12 ? 'tooltip-top' : ""} data-tooltip={data.settings.title && data.settings.title.length > 12 ? data.settings.title : ""}>{data.settings.title && data.settings.title.length > 12 ? data.settings.title.substring(0, 10) + '..' : data.settings.title}</span>
                                </p>
                            </td>
                            <td className='infoLink'>
                                <p className='tooltip-top' data-tooltip={data.recipients && data.recipients.users ? tooltipFullName(data.recipients.users) : ""}>{data.recipients && data.recipients.users && transformFullName(data.recipients.users)}</p></td>
                            <td>
                                <p className={token.user.firstName ? 'tooltip-top' : ""} data-tooltip={token.user.firstName ? `${token.user.firstName} ${token.user.lastName}` : ""}>
                                    {token.user.firstName && token.user.firstName.length > 12 ? token.user.firstName.substring(0, 10) + '..' : token.user.firstName}
                                </p>
                            </td>
                            <td>{data.recipients.senderType && data.recipients.senderType}</td>
                            <td>{moment(data.createdAt).format('MMM DD')}</td>
                            <td>{data.settings.dueDate ? moment(data.settings.dueDate).format('MMM DD') : "N/A"}</td>
                            <td>{moment(data.updatedAt).format('MMM DD')}</td>
                            <td>{sumFileSize(data.files)}</td>
                            <td className='download'>
                                {data.isMarkedForDeletion === true ?
                                    <button className='editBtn' onClick={() => handleWarningShow(data.sendId)}>Delete</button>
                                    : <button className='editBtn' onClick={() => editFile(data.sendId)}>Edit</button>
                                }
                                <button onClick={() => downloadFile(data)}>Download</button>
                                {filterTag === "delete" ? <button style={{ marginLeft: '10px' }} onClick={() => RestoreFile(data.sendId)}>Restore</button> : ""}
                            </td>
                        </tr>)}
                    </> : <tr>
                        <td className='text-center fileEmpty' colSpan={10}>There are no send files available for display</td>
                    </tr>
                    }
                </tbody>
            </Table>
            <WarningModal headingText={headingText} paragraphText={paragraphText} deleteEmployee={sentFileApi} handleWarningClose={handleWarningClose} warningShow={warningShow} />
        </>
    )
}

export default SentTable